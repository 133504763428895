import { useGlobalStore } from 'global-state/useStore';
import React from 'react';
import Menu from '@mui/material/Menu';
import { useAuth } from 'rq/hooks/authHook';
import { ISmallScreensProfileMenu } from './types';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Logout from '@mui/icons-material/Logout';
import Avatar from '@mui/material/Avatar';
import DomainIcon from '@mui/icons-material/Domain';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

const SmallScreensMenu = (props: ISmallScreensProfileMenu) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { data: user } = useAuth();
	const orgId = useGlobalStore((state) => state.currentOrganization.id);
	const currentOrganization = user?.organizations?.find((org) => org.id === orgId);

	return (
		<Menu
			anchorEl={props.anchorEl}
			open={props.openMenu}
			onClose={props.handleClose}
			onClick={props.handleClose}
			PaperProps={{
				elevation: 0,
				sx: {
					overflow: 'visible',
					filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
					mt: 1.5,
					'& .MuiAvatar-root': {
						width: 32,
						height: 32,
						ml: -0.5,
						mr: 1
					},
					'&:before': {
						content: '""',
						display: 'block',
						position: 'absolute',
						top: 0,
						right: 14,
						width: 10,
						height: 10,
						bgcolor: 'background.paper',
						transform: 'translateY(-50%) rotate(45deg)',
						zIndex: 0
					}
				}
			}}
			transformOrigin={{ horizontal: 'right', vertical: 'top' }}
			anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
			<MenuItem onClick={props.handleModalOpen}>
				{currentOrganization?.logo ? (
					<Avatar src={currentOrganization.logo} />
				) : (
					<Avatar className="bg-primary">
						<DomainIcon />
					</Avatar>
				)}
				{currentOrganization?.name}
			</MenuItem>
			<Divider />
			<MenuItem onClick={() => navigate(`/${orgId}/users/${user?.id}`)}>
				<Avatar alt="user" src={user?.image} /> {t('PROFILE')}
			</MenuItem>
			<Divider />
			<MenuItem onClick={props.logoutHandler}>
				<ListItemIcon>
					<Logout fontSize="small" />
				</ListItemIcon>
				{t('LOGOUT')}
			</MenuItem>
		</Menu>
	);
};

export default SmallScreensMenu;
