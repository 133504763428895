import Button from '@mui/material/Button';
import Modal from 'components/shared/Modal/Modal';
import { useGlobalStore } from 'global-state/useStore';
import React, { useState } from 'react';
import { DateRange, DateRangePicker } from 'react-date-range';
import { useTranslation } from 'react-i18next';

const DateRangeModal = () => {
	const { t } = useTranslation();

	const setSelectedMeasurementId = useGlobalStore((state) => state.setSelectedMeasurementId);
	const toggleMeasurementsCalendar = useGlobalStore((state) => state.toggleMeasurementsCalendar);
	const { date_from, date_to, key, open } = useGlobalStore((state) => state.measurementsCalendar);
	const [selectedRange, setSelectedRange] = useState([
		{ startDate: date_from, endDate: date_to, key: key }
	]);

	const applyDateRange = () => {
		setSelectedMeasurementId(undefined);
		toggleMeasurementsCalendar({
			open: false,
			date_from: selectedRange[0].startDate,
			date_to: selectedRange[0].endDate,
			key: selectedRange[0].key ?? ''
		});
	};

	return (
		<Modal
			closeModal={() =>
				toggleMeasurementsCalendar({
					open: false,
					date_from: date_from,
					date_to: date_to,
					key: key
				})
			}
			modalTitle={t('TIME_PERIOD')}
			open={open}>
			<div className={'flex flex-1 flex-col items-center justify-center gap-4'}>
				<div className={'hidden h-full w-full md:block'}>
					<DateRangePicker
						moveRangeOnFirstSelection={false}
						ranges={selectedRange}
						onChange={(item) => {
							setSelectedRange([
								{
									startDate: item.range1.startDate,
									endDate: item.range1.endDate,
									key: item.range1.key ?? ''
								}
							]);
						}}
						months={2}
						direction="horizontal"
					/>
				</div>
				<div className={'flex  w-full justify-center md:hidden'}>
					<DateRange
						onChange={(item) => {
							setSelectedRange([
								{
									startDate: item.range1.startDate,
									endDate: item.range1.endDate,
									key: item.range1.key ?? ''
								}
							]);
						}}
						moveRangeOnFirstSelection={false}
						ranges={selectedRange}
						months={2}
						direction="vertical"
					/>
				</div>
				<div className={'pb-4'}>
					<Button
						variant={'contained'}
						className={'bg-secondary normal-case'}
						onClick={applyDateRange}>
						{t('APPLY')}
					</Button>
				</div>
			</div>
		</Modal>
	);
};
export default DateRangeModal;
