import { useGlobalStore } from 'global-state/useStore';
import React, { useEffect, useState } from 'react';
import { Button, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useIsProcessingDone } from 'rq/hooks/cloudProcessingHook';
import { PROCESSING_STEPS } from 'pages/CloudProcessing/ProcessingSteps';
import { toast } from 'react-hot-toast';

const Processing = () => {
	const { t } = useTranslation();

	const displayStep = useGlobalStore((state) => state.displayStep);
	const currentStep = useGlobalStore((state) => state.currentStep);
	const setDisplayStep = useGlobalStore((state) => state.setDisplayStep);
	const setCurrentStep = useGlobalStore((state) => state.setCurrentStep);
	const displacementProcessing = useGlobalStore((state) => state.displacementProcessing);
	const videoBeingProcessed = useGlobalStore((state) => state.videoBeingProcessed);
	const setVideoBeingProcessed = useGlobalStore((state) => state.setVideoBeingProcessed);
	const cloudProcessing = useGlobalStore((state) => state.cloudProcessing);
	const processingTimeoutReached = useGlobalStore((state) => state.processingTimeoutReached);
	const handleProcessingTimeoutReached = useGlobalStore(
		(state) => state.handleProcessingTimeoutReached
	);

	const [refetchTimeout, setRefetchTimeout] = useState<NodeJS.Timeout>();
	const [error, setError] = useState<string>();

	const setupTimeout = () => {
		setRefetchTimeout(
			setTimeout(() => {
				handleProcessingTimeoutReached(true);
			}, 120000)
		);
	};

	useEffect(() => {
		setupTimeout();
	}, []);

	const { data: processingDone } = useIsProcessingDone(
		{
			processingId: `${cloudProcessing.video?.processing_id}`
		},
		{
			refetchInterval: 2500,
			enabled: videoBeingProcessed && !processingTimeoutReached,
			onSuccess: (data) => {
				if (data.processing_status === 'ERROR' && refetchTimeout) {
					setVideoBeingProcessed(false);
					handleProcessingTimeoutReached(false);
					clearTimeout(refetchTimeout);
					setError(t('PROCESSING_ERROR'));
					toast.error(t('PROCESSING_ERROR'));
				}
				if (data.processing_status === 'DONE' && refetchTimeout) {
					setVideoBeingProcessed(false);
					handleProcessingTimeoutReached(false);
					clearTimeout(refetchTimeout);

					const nextStep =
						PROCESSING_STEPS[
							displacementProcessing
								? Number(displayStep.nextDisplacementStep)
								: cloudProcessing.configManuallyUploaded
								? displayStep.stepNumber + 3
								: displayStep.stepNumber + 1
						];
					if (nextStep) {
						if (currentStep.stepNumber <= displayStep.stepNumber) {
							setCurrentStep(nextStep);
						}
					}
					setCurrentStep(nextStep);
					setDisplayStep(nextStep);
				}
			}
		}
	);

	const tryAgainHandler = () => {
		handleProcessingTimeoutReached(false);
		setupTimeout();
	};

	return (
		<div className="text-lg text-secondary">
			<div className="inline-flex flex-col items-center">
				{videoBeingProcessed && !processingTimeoutReached && (
					<>
						<div className="mt-4 text-base text-black">{t('PROCESSING_VIDEO_IN_PROGRESS')}</div>
						<CircularProgress className=" text-blue my-8 h-12 w-12" />
						<Button className="normal-case" variant="contained" color="error">
							{t('CANCEL')}
						</Button>
					</>
				)}
				{!videoBeingProcessed && processingDone && processingDone.processing_status === 'DONE' && (
					<>
						<div>{error}</div>
						<Button
							onClick={() => {
								setDisplayStep(PROCESSING_STEPS[3]);
								setCurrentStep(PROCESSING_STEPS[3]);
							}}
							className="mt-8 normal-case"
							variant="contained"
							color="success">
							{t('PROCEED_TO_RESULTS')}
						</Button>
					</>
				)}
				{processingTimeoutReached &&
					processingDone &&
					processingDone.processing_status === 'PENDING' && (
						<>
							<div>This is taking too long.</div>
							<Button
								onClick={tryAgainHandler}
								className="mt-16 normal-case"
								variant="contained"
								color="info">
								{t('CHECK_THE_RESULTS_AGAIN')}
							</Button>
						</>
					)}
				{!videoBeingProcessed && processingDone && processingDone.processing_status === 'ERROR' && (
					<>
						<div>{t('PROCESSING_ERROR')}</div>
						<Button
							onClick={() => {
								setCurrentStep(PROCESSING_STEPS[0]);
								setDisplayStep(PROCESSING_STEPS[0]);
							}}
							className="mt-16 normal-case"
							variant="contained"
							color="info">
							{t('TRY_AGAIN')}
						</Button>
					</>
				)}
			</div>
		</div>
	);
};

export default Processing;
