import { History } from '@mui/icons-material';
import { IViewMeasurementsButtonProps } from 'pages/SiteDetails/components/types';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

const ViewMeasurementsButton: FunctionComponent<IViewMeasurementsButtonProps> = ({
	handleClick
}) => {
	const { t } = useTranslation();

	return (
		<>
			<div className={'flex flex-row gap-2 align-middle'}>
				<History className={'text-gray-500'}></History>
				<a className={' cursor-pointer text-secondary underline'} onClick={handleClick}>
					{t('CONFIG_HISTORICAL_OVERVIEW')}
				</a>
			</div>
		</>
	);
};
export default ViewMeasurementsButton;
