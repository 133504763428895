import { Add, Alarm, Delete, EditLocation, Save, Settings, Waves } from '@mui/icons-material';
import LocationIcon from '@mui/icons-material/LocationOn';
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart';
import EditIcon from '@mui/icons-material/Edit';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Card from 'components/shared/Cards/Card';
import { TabPanel } from 'components/shared/TabPanel/TabPanel';
import IconWithTooltip from 'components/shared/Tooltip/IconWithTooltip';
import { format } from 'date-fns';
import { useGlobalStore } from 'global-state/useStore';
import { checkPermissions, convertToUsersTimezone } from 'helper/helperFunctions';
import { SiteBasicModel } from 'interfaces/models/SiteBasicModel';
import { StationSimplified } from 'interfaces/models/StationSimplified';
import { Icon, LatLngTuple, PointExpression } from 'leaflet';
import CustomStationTab from 'pages/SiteDetails/components/StationTabs/CustomStationTab';
import DischargeKeeperStationTab from 'pages/SiteDetails/components/StationTabs/DischargeKeeperStationTab';

import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { queryKeys } from 'rq/constants';
import { useAlarms } from 'rq/hooks/alarmsHook';
import { useGetLastMeasurements, useSite, useUpdateSite } from 'rq/hooks/sitesHook';
import { Button, Tab, Tabs } from '@mui/material';
import { useStations } from 'rq/hooks/stationHook';
import { queryClient } from 'rq/queryClient';
import SitesService from 'services/SitesService/SitesService';
import DischargeStationTab from 'pages/SiteDetails/components/StationTabs/DischargeStationTab';
import PermissionView from 'components/shared/PermissionsView/PermissionView';

const SiteDetailsPage: FunctionComponent = () => {
	const { t } = useTranslation();
	const { siteId } = useParams();
	const navigate = useNavigate();
	const location = useLocation();

	const currentOrganization = useGlobalStore((state) => state.currentOrganization);
	const toggleCreateStationModal = useGlobalStore((state) => state.toggleCreateStationModal);
	const setSelectedSiteId = useGlobalStore((state) => state.setSelectedSiteId);
	const toggleChangeSiteLocationModal = useGlobalStore(
		(state) => state.toggleChangeSiteLocationModal
	);
	const toggleSiteImageModal = useGlobalStore((state) => state.toggleSiteImageModal);
	const toggleConfirmationDialog = useGlobalStore((state) => state.toggleConfirmationDialog);

	const { timezone_display } = useGlobalStore((state) => state.userInfo);

	const { data: siteDetails, isLoading: siteDetailsLoading } = useSite({ site_id: Number(siteId) });

	const { mutate: updateSite } = useUpdateSite<SiteBasicModel>();
	const { data: stations, isLoading: stationsLoading } = useStations(Number(siteId));
	const { data: alarms } = useAlarms(currentOrganization.id.toString(), {
		enabled: checkPermissions(['manage_organization'])
	});
	const { data: sites } = useGetLastMeasurements({
		last_measurements: true,
		sub: false
	});

	const mapRef = useRef<L.Map>(null);

	const [mapPosition, setMapPosition] = useState([
		siteDetails?.latitude ?? 0,
		siteDetails?.longitude ?? 0
	] as LatLngTuple);

	const [activeAlarms, setActiveAlarms] = useState(
		alarms?.filter((alarm) => alarm.site === siteDetails?.site_id)
	);

	const [tabValue, setTabValue] = useState<number>(0);
	const [siteName, setSiteName] = useState(siteDetails ? siteDetails.name : '');

	useEffect(() => {
		setSelectedSiteId(Number(siteId));
	}, [siteId]);

	useEffect(() => {
		const stationIndex = stations?.findIndex(
			(value) => value.station_id === location.state?.stationId
		);

		stationIndex && stationIndex !== -1 && setTabValue(stationIndex);
	}, [location]);

	useEffect(() => {
		siteDetails && siteDetails.name && setSiteName(siteDetails.name);
		siteDetails && setMapPosition([siteDetails?.latitude, siteDetails?.longitude] as LatLngTuple);
	}, [siteDetails]);

	const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
		setTabValue(newValue);
	};

	useEffect(() => {
		if (location.state && location.state.stationId) {
			const stationIndex = stations?.findIndex(
				(value) => value.station_id === location.state?.stationId
			);
			stationIndex && stationIndex !== -1 && setTabValue(stationIndex);
		} else setTabValue(0);
	}, [stations]);

	useEffect(() => {
		mapRef.current?.flyTo(
			[Number(siteDetails?.latitude), Number(siteDetails?.longitude)],
			mapRef.current?.getZoom(),
			{
				duration: 0.1
			}
		);
	}, [mapPosition, siteDetails?.latitude, siteDetails?.longitude]);

	useEffect(() => {
		setActiveAlarms(alarms?.filter((alarm) => alarm.site === siteDetails?.site_id));
	}, [siteDetails, alarms]);

	const markerIcon = new Icon({
		iconUrl: '/assets/Map_marker.svg',
		iconSize: [40, 40] as PointExpression
	});

	const deleteSiteImage = () => {
		SitesService.deleteSiteImage(Number(siteId)).then(() => {
			toast.success(t('IMAGE_REMOVED'));
			queryClient.invalidateQueries([queryKeys.sites, queryKeys.site, Number(siteId)]);
			queryClient.invalidateQueries(queryKeys.sites_paginated);
		});
	};

	const editSiteName = () => {
		const dataForSubmit = {
			site_id: Number(siteDetails?.site_id),
			name: `${siteName}`,
			latitude: Number(siteDetails?.latitude),
			longitude: Number(siteDetails?.longitude)
		};
		if (dataForSubmit) {
			updateSite(dataForSubmit, {
				onSuccess: () => {
					toast.success(t('SITE_UPDATED'));

					queryClient.invalidateQueries([queryKeys.sites, queryKeys.site, Number(siteId)]);
				}
			});
		}
	};

	const findLastMeasurement = () => {
		let lastMeasurementDate = '';
		if (sites && siteId) {
			const foundSite = sites.find((site) => site.site_id.toString() === siteId);
			lastMeasurementDate = foundSite?.last_measurements
				? foundSite.last_measurements[0]?.date_time
				: '';
		}
		return lastMeasurementDate;
	};

	const [lastMeasurement] = useState(findLastMeasurement());

	if (siteDetailsLoading || stationsLoading)
		return (
			<div className={'app flex h-full flex-col items-center justify-center text-secondary'}>
				<CircularProgress size={75} thickness={5} />
			</div>
		);

	const handleTabName = (station: StationSimplified) => {
		if (station.station_name !== null) return station.station_name;
		else if (station.station_type === 'DISCHARGE') return 'DISCHARGE APP';
		else return station.station_type.replace('_', ' ');
	};

	return (
		<div className={'flex w-full flex-col md:px-10'}>
			<div className={'w-full flex-row'}>
				<div className={'flex flex-row items-center align-middle'}>
					<div className={'mr-2'}>
						<LocationIcon fontSize={'large'} className={'h-10 w-full object-cover'} />
					</div>
					<TextField
						className={'text-xl font-bold'}
						value={siteName}
						disabled={!checkPermissions('manage_sites')}
						InputProps={{
							style: { fontSize: 'xx-large', color: 'black' }
						}}
						onChange={(e) => setSiteName(e.target.value)}
						variant={'standard'}></TextField>
					{siteName !== siteDetails?.name && (
						<IconWithTooltip
							icon={
								<Save
									className={'ml-4 cursor-pointer text-secondary'}
									onClick={editSiteName}></Save>
							}
							title={t('EDIT_SITE_NAME')}></IconWithTooltip>
					)}
				</div>
			</div>

			<div className={'max-w-2/3 mt-8 flex w-full flex-row flex-wrap gap-8'}>
				<div className={'h-56 w-80'}>
					<Card
						actionIcon={
							<PermissionView requiredPermissions={['manage_sites']} showFallbackComponent={false}>
								<IconWithTooltip
									icon={<Settings className={'ml-4 cursor-pointer text-gray-500'}></Settings>}
									title={t('SITE_SETTINGS')}></IconWithTooltip>
							</PermissionView>
						}>
						<div className={'flex flex-1 flex-col gap-3 p-5'}>
							<div className={'flex w-full justify-end text-xs'}>
								{t('ID')}: {siteDetails?.site_id}
							</div>
							<div className={'flex flex-row items-end justify-between align-bottom'}>
								<span> {stations?.length === 1 ? t('STATION') : t('STATIONS')}</span>
								<span className={'w-28'}>{stations?.length}</span>
							</div>
							{/*	<div className={'flex flex-row items-end justify-between align-bottom'}>
								<span> {t('MEASUREMENTS')}</span>
								<span className={'w-28'}>10000</span>
							</div>*/}
							<div className={'flex flex-row items-center justify-between align-bottom'}>
								<div> {t('LAST_MEASUREMENT')}</div>
								<div className={'w-28'}>
									{lastMeasurement
										? format(
												convertToUsersTimezone(new Date(lastMeasurement), {}, timezone_display),
												'dd-MM-yyyy'
										  )
										: t('NO_MEASUREMENTS')}
								</div>
							</div>
							<PermissionView
								requiredPermissions={['manage_organization']}
								showFallbackComponent={false}>
								<div className={'flex flex-row gap-2 align-middle'}>
									<Alarm className={'text-gray-500'}></Alarm>
									<a
										className={' cursor-pointer text-secondary underline'}
										onClick={() =>
											navigate(`/${currentOrganization.id}/alarms`, {
												state: {
													site_id: siteDetails?.site_id
												}
											})
										}>
										{activeAlarms?.length ? (
											<>
												{' '}
												{activeAlarms?.length} {t('ACTIVE_ALARMS')}
											</>
										) : (
											<>{t('NO_ALARMS_FOR_SITE')}</>
										)}
									</a>
								</div>
							</PermissionView>
						</div>
					</Card>
				</div>
				<div className={'h-56 w-80 '}>
					<Card
						actionIcon={
							<PermissionView requiredPermissions={['manage_sites']} showFallbackComponent={false}>
								<>
									{' '}
									<IconWithTooltip
										icon={
											<EditIcon
												className={'ml-4 cursor-pointer text-gray-500'}
												onClick={toggleSiteImageModal}></EditIcon>
										}
										title={t('CHANGE_IMAGE')}></IconWithTooltip>{' '}
									<IconWithTooltip
										icon={
											<Delete
												className={'ml-4 cursor-pointer text-danger'}
												onClick={() => {
													toggleConfirmationDialog({
														type: 'confirmation',
														dialogOpen: true,
														confirmAction: () => deleteSiteImage(),
														message: t('REMOVE_SITE_IMAGE_PROMPT')
													});
												}}></Delete>
										}
										title={t('REMOVE_IMAGE')}></IconWithTooltip>
								</>
							</PermissionView>
						}>
						<div>
							{siteDetails?.image ? (
								<img
									src={siteDetails?.image}
									className={'h-48 w-full rounded-t-xl object-cover'}
									alt={'site_image'}
								/>
							) : (
								<Waves className={'h-48 w-full object-cover text-secondary'} />
							)}
						</div>
					</Card>
				</div>
				<div className={'h-56 w-80'}>
					<Card
						actionIcon={
							<div className={'flex gap-3'}>
								<div className={'overflow-hidden'}>
									{' '}
									{siteDetails?.latitude.toFixed(7)}, {siteDetails?.longitude.toFixed(7)}
								</div>
								<PermissionView
									requiredPermissions={['manage_sites']}
									showFallbackComponent={false}>
									<IconWithTooltip
										icon={
											<EditLocation
												className={' cursor-pointer text-gray-500'}
												onClick={toggleChangeSiteLocationModal}></EditLocation>
										}
										title={t('CHANGE_LOCATION')}></IconWithTooltip>
								</PermissionView>
							</div>
						}>
						<div
							className={'z-50 h-48 w-full cursor-pointer'}
							onClick={() => {
								siteDetails && setSelectedSiteId(siteDetails.site_id);
								navigate(`/${currentOrganization.id}/map`, {
									state: {
										latitude: siteDetails?.latitude,
										longitude: siteDetails?.longitude,
										siteId: siteDetails?.site_id
									}
								});
							}}>
							<MapContainer
								center={mapPosition}
								className={'rounded-t-xl'}
								zoom={18}
								scrollWheelZoom={false}
								bounceAtZoomLimits={true}
								zoomControl={false}
								dragging={false}
								ref={mapRef}
								minZoom={18}
								maxZoom={18}>
								<TileLayer
									attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
									url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png"
									maxZoom={25}
									className={'rounded-t-md'}
									maxNativeZoom={19}
								/>
								<Marker
									position={[Number(siteDetails?.latitude), Number(siteDetails?.longitude)]}
									icon={markerIcon}></Marker>
							</MapContainer>
						</div>
					</Card>
				</div>
			</div>
			<div className={'mt-20 h-full max-w-7xl'}>
				<div className={'flex flex-row items-center align-middle'}>
					<div className={'mr-2'}>
						<StackedBarChartIcon fontSize={'large'} className={'h-10 w-full object-cover'} />
					</div>
					<div className={'text-3xl'}>{t('STATIONS')}</div>
				</div>

				<div
					className={
						'mt-8 h-full  min-h-[20rem]  w-full max-w-screen-lg rounded-xl border border-black'
					}>
					<div className={'flex justify-between max-md:flex-col'}>
						<Tabs
							value={tabValue}
							onChange={handleTabChange}
							variant="scrollable"
							scrollButtons="auto"
							aria-label="scrollable auto tabs">
							{stations?.map((station, index) => {
								return (
									<Tab
										label={handleTabName(station)}
										value={index}
										className={'normal-case'}
										key={`station-${index}-id-${station.station_id}`}
									/>
								);
							})}
						</Tabs>
						<PermissionView requiredPermissions={['manage_sites']} showFallbackComponent={false}>
							<div className={'flex justify-end px-2 pt-1 '} onClick={toggleCreateStationModal}>
								<Button
									variant={'contained'}
									className={' h-9 w-40 rounded-xl bg-accent normal-case md:text-base'}>
									<div>
										<span className={'mr-1'}>
											<Add className={'text-lg'} />
										</span>
										{t('NEW_STATION')}
									</div>
								</Button>
							</div>
						</PermissionView>
					</div>
					<div className={' border-t border-t-black max-md:mt-1 '}>
						{stations?.map((station, index) => {
							switch (station.station_type) {
								case 'DISCHARGE':
									return (
										<TabPanel
											value={tabValue}
											index={index}
											key={`station-${station.station_id}-tabpanel`}>
											<div className="p-4 ">
												<DischargeStationTab
													station_id={station.station_id}
													stationDetails={stations.find(
														(stationItem) => stationItem.station_id === station.station_id
													)}></DischargeStationTab>
											</div>
										</TabPanel>
									);
								case 'DISCHARGE_KEEPER':
									return (
										<TabPanel
											value={tabValue}
											index={index}
											key={`station-${station.station_id}-tabpanel`}>
											<div className="p-4 ">
												<DischargeKeeperStationTab
													station_id={station.station_id}
													stationDetails={stations.find(
														(stationItem) => stationItem.station_id === station.station_id
													)}></DischargeKeeperStationTab>
											</div>
										</TabPanel>
									);
								case 'CUSTOM_STATION':
									return (
										<TabPanel
											value={tabValue}
											index={index}
											key={`station-${station.station_id}-tabpanel`}>
											<div className="p-4 ">
												<CustomStationTab
													station_id={station.station_id}
													stationDetails={stations.find(
														(stationItem) => stationItem.station_id === station.station_id
													)}></CustomStationTab>
											</div>
										</TabPanel>
									);
							}
						})}
					</div>
				</div>
			</div>
		</div>
	);
};
export default SiteDetailsPage;
