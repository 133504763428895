import { Button, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useGlobalStore } from 'global-state/useStore';
import React from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { queryKeys } from 'rq/constants';
import { useUpdateStation } from 'rq/hooks/stationHook';
import { queryClient } from 'rq/queryClient';
import * as Yup from 'yup';

const GCPMeasurements = () => {
	const { t } = useTranslation();
	const { stationDetails } = useGlobalStore((state) => state.editGCPModal);
	const selectedSiteId = useGlobalStore((state) => state.selectedSiteId);
	const { mutate: updateStation } = useUpdateStation();
	const formik = useFormik({
		initialValues: {
			markers_1_2: stationDetails?.markers_measurements
				? stationDetails.markers_measurements[0]
				: null,
			markers_3_4: stationDetails?.markers_measurements
				? stationDetails.markers_measurements[1]
				: null,
			markers_1_4: stationDetails?.markers_measurements
				? stationDetails.markers_measurements[2]
				: null,
			markers_2_3: stationDetails?.markers_measurements
				? stationDetails.markers_measurements[3]
				: null,
			dfs_1_2: stationDetails?.markers_measurements ? stationDetails.markers_measurements[4] : null,
			dfs_3_4: stationDetails?.markers_measurements ? stationDetails.markers_measurements[5] : null,
			dfs_1_4: stationDetails?.markers_measurements ? stationDetails.markers_measurements[6] : null,
			dfs_2_3: stationDetails?.markers_measurements ? stationDetails.markers_measurements[7] : null
		},
		validationSchema: Yup.object({
			markers_1_2: Yup.number().required(),
			markers_3_4: Yup.number().required(),
			markers_1_4: Yup.number().required(),
			markers_2_3: Yup.number().required(),
			dfs_1_2: Yup.number().required(),
			dfs_3_4: Yup.number().required(),
			dfs_1_4: Yup.number().required(),
			dfs_2_3: Yup.number().required()
		}),
		onSubmit: (values) => {
			/*	const station = {
          ...stationDetails,
          markers_measurements: values.markers_measurements.split(',').map(Number)
        };*/
			const markerMeasurements = Object.values(values).concat([0, 0, 0, 0, 0, 0]);
			const station = {
				...stationDetails,
				markers_measurements: markerMeasurements
			};

			stationDetails?.station_id &&
				updateStation(
					{
						siteId: Number(selectedSiteId),
						stationId: stationDetails?.station_id,
						data: station
					},
					{
						onSuccess: () => {
							toast.success(t('STATION_UPDATED'));
							queryClient.invalidateQueries([
								queryKeys.station_details,
								`site-id-${selectedSiteId}`,
								`station-id-${stationDetails.station_id}`
							]);
						}
					}
				);
		}
	});
	return (
		<form onSubmit={formik.handleSubmit}>
			<div className={'flex w-full flex-col gap-8'}>
				<div className={'flex flex-col gap-2'}>
					<div>{t('DISTANCE_BETWEEN_MARKERS')} (m)</div>
					<div className={'flex flex-wrap  gap-2'}>
						<TextField
							variant={'standard'}
							type={'number'}
							className={'w-32'}
							label={`${t('MARKERS')} 1-2 (m)*`}
							name="markers_1_2"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.markers_1_2}
							error={formik.touched.markers_1_2 && Boolean(formik.errors.markers_1_2)}
						/>
						<TextField
							variant={'standard'}
							type={'number'}
							className={'w-32'}
							label={`${t('MARKERS')} 3-4 (m)*`}
							name="markers_3_4"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.markers_3_4}
							error={formik.touched.markers_3_4 && Boolean(formik.errors.markers_3_4)}
						/>
						<TextField
							variant={'standard'}
							type={'number'}
							className={'w-32'}
							label={`${t('MARKERS')} 1-4 (m)*`}
							name="markers_1_4"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.markers_1_4}
							error={formik.touched.markers_1_4 && Boolean(formik.errors.markers_1_4)}
						/>
						<TextField
							variant={'standard'}
							type={'number'}
							className={'w-32'}
							label={`${t('MARKERS')} 2-3 (m)*`}
							name="markers_2_3"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.markers_2_3}
							error={formik.touched.markers_2_3 && Boolean(formik.errors.markers_2_3)}
						/>
					</div>
				</div>
				<div className={'flex flex-col gap-2'}>
					<div>{t('DISTANCE_FROM_SHORE')} (m)</div>
					<div className={'flex flex-wrap  gap-2'}>
						<TextField
							variant={'standard'}
							type={'number'}
							className={'w-32'}
							label={`${t('DFS')} 1-2 (m)*`}
							name="dfs_1_2"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.dfs_1_2}
							error={formik.touched.dfs_1_2 && Boolean(formik.errors.dfs_1_2)}
						/>
						<TextField
							variant={'standard'}
							type={'number'}
							className={'w-32'}
							label={`${t('DFS')} 3-4 (m)*`}
							name="dfs_3_4"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.dfs_3_4}
							error={formik.touched.dfs_3_4 && Boolean(formik.errors.dfs_3_4)}
						/>
						<TextField
							variant={'standard'}
							type={'number'}
							className={'w-32'}
							label={`${t('DFS')} 1-4 (m)*`}
							name="dfs_1_4"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.dfs_1_4}
							error={formik.touched.dfs_1_4 && Boolean(formik.errors.dfs_1_4)}
						/>
						<TextField
							variant={'standard'}
							type={'number'}
							className={'w-32'}
							label={`${t('DFS')} 2-3 (m)*`}
							name="dfs_2_3"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.dfs_2_3}
							error={formik.touched.dfs_2_3 && Boolean(formik.errors.dfs_2_3)}
						/>
					</div>
				</div>
				<div className={'flex w-full justify-center'}>
					<div className={'w-full xl:w-3/4'}>
						<img src={'/assets/furrow_geom4markers_new.png'} alt={'4 markers geometry'} />
					</div>
				</div>
				<div className={'flex flex-row justify-center py-4'}>
					<Button
						variant={'contained'}
						type={'submit'}
						disabled={!formik.isValid || !formik.dirty}
						className={'normal-case'}>
						{t('UPDATE')}
					</Button>
				</div>
			</div>
		</form>
	);
};
export default GCPMeasurements;
