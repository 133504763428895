import { Button } from '@mui/material';
import { useGlobalStore } from 'global-state/useStore';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { PROCESSING_STEPS } from 'pages/CloudProcessing/ProcessingSteps';
import {
	useCloudProcessingResult,
	useConfirmAndSaveMeasurement
} from 'rq/hooks/cloudProcessingHook';
import { handleCanvasDrawing } from 'utils/functions';
import { ProcessingResultsData } from 'services/CloudProcessingService/types';
import { MeasurementsService } from 'services/MeasurementsService/MeasurementsService';

const NormalProcessingResults = () => {
	const location = useLocation();

	const { t } = useTranslation();
	const navigate = useNavigate();
	const imgRef = useRef<HTMLImageElement>(null);
	const canvasRef = useRef<HTMLCanvasElement>(null);
	const originalImgRef = useRef<HTMLImageElement>(null);
	const originalCanvasRef = useRef<HTMLCanvasElement>(null);

	const setDisplayStep = useGlobalStore((state) => state.setDisplayStep);
	const setCurrentStep = useGlobalStore((state) => state.setCurrentStep);
	const cloudProcessing = useGlobalStore((state) => state.cloudProcessing);
	const processingTimeoutReached = useGlobalStore((state) => state.processingTimeoutReached);
	const handleProcessingTimeoutReached = useGlobalStore(
		(state) => state.handleProcessingTimeoutReached
	);

	const { originalReprocessingData, reprocessing } = useGlobalStore(
		(state) => state.reprocessingMeasurement
	);

	const { data: processingResults } = useCloudProcessingResult(
		{
			processing_id: `${cloudProcessing.video?.processing_id}`
		},
		{
			staleTime: 0,
			cacheTime: 0
		}
	);

	const { mutate: confirmAndSaveMeasurementRequest } = useConfirmAndSaveMeasurement();

	useEffect(() => {
		if (!processingResults || !canvasRef.current || !imgRef.current) {
			return;
		}
		handleCanvasDrawing(canvasRef, imgRef, processingResults, cloudProcessing);
	}, [processingResults]);

	useEffect(() => {
		if (!originalReprocessingData || !originalCanvasRef.current || !originalImgRef.current) {
			return;
		}
		handleCanvasDrawing(
			originalCanvasRef,
			originalImgRef,
			originalReprocessingData?.measurement_orginal as unknown as ProcessingResultsData,
			cloudProcessing
		);
	}, [originalReprocessingData, originalImgRef, originalCanvasRef, processingResults]);

	useEffect(() => {
		if (processingTimeoutReached) handleProcessingTimeoutReached(false);
	}, []);

	const confirmAndSaveMeasurement = () => {
		const urlParts = location.pathname.split('/');
		if (reprocessing && processingResults) {
			MeasurementsService.updateMeasurement({
				measurement: processingResults,
				measurement_id: Number(originalReprocessingData?.measurement_orginal.measurement_id),
				station_id: Number(originalReprocessingData?.station.station_id),
				site_id: Number(urlParts[3])
			})
				.then(() => {
					toast.success(t('MEASUREMENT_UPDATED'));
					navigate('/');
				})
				.catch((err) => {
					toast.error(err);
				});
		} else
			confirmAndSaveMeasurementRequest(
				{
					processing_id: `${cloudProcessing.video?.processing_id}`
				},
				{
					onSuccess: () => {
						toast.success(t('MEASUREMENT_SAVED'));
						navigate('/');
					}
				}
			);
	};

	const discardMeasurement = () => {
		const GCPorUploadStep = !cloudProcessing.configManuallyUploaded
			? PROCESSING_STEPS.find((step) => step.name === 'GCP')
			: PROCESSING_STEPS.find((step) => step.name === 'UPLOAD');
		GCPorUploadStep && setCurrentStep(GCPorUploadStep);
		GCPorUploadStep && setDisplayStep(GCPorUploadStep);
	};

	const [containerWidthClass, setContainerWidthClass] = useState('w-[25rem]');

	const onImageLoad = () => {
		if (imgRef.current && canvasRef.current) {
			if (imgRef.current.naturalWidth > imgRef.current.naturalHeight) {
				setContainerWidthClass('w-full max-w-full xl:w-[65rem]');
			} else {
				setContainerWidthClass('w-full max-w-full xs:w-[30rem] md:w-[25rem]');
			}
		}
	};

	return (
		<div className="mt-6 ml-6 text-lg text-secondary">
			<div className="flex flex-col">
				<div className="flex w-full flex-row flex-wrap justify-between">
					<div>
						<div className="mb-4 ml-2">{t('MEASUREMENT_RESULTS')}</div>

						<div className="flex  flex-row flex-wrap-reverse ">
							<div className={'mr-4 flex flex-col items-center justify-center gap-4'}>
								<div className="relative">
									<img
										id={'measImg'}
										ref={imgRef}
										src={cloudProcessing.rotatedImage?.imgSrc}
										className={`${containerWidthClass} max-w-full`}
										alt={t('MEASUREMENT_IMAGE')}
									/>
									<canvas className={`absolute top-0 left-0 h-full `} ref={canvasRef} />
								</div>
							</div>

							{processingResults && (
								<div className="mb-4 flex flex-col text-xl text-black">
									<div className="mb-3 font-light">
										{t('DISCHARGE')}:{' '}
										<span className="font-bold">
											{processingResults.discharge !== null
												? processingResults.discharge.toFixed(3)
												: '-'}{' '}
											m<sup>3</sup>/s
										</span>
									</div>

									<div className="mb-3 font-light">
										{t('WATER_COLUMN')}:{' '}
										<span className="font-bold">
											{processingResults.level !== null ? processingResults.level.toFixed(3) : '-'}{' '}
											m
										</span>
									</div>
									<div className="mb-3 font-light">
										{t('MAX_SURFACE_VELOCITY')}:{' '}
										<span className="font-bold">
											{processingResults.velocity !== null
												? processingResults.velocity.toFixed(3)
												: '-'}{' '}
											m/s
										</span>
									</div>
									<div className="mb-3 font-light">
										{t('MEAN_SURFACE_VELOCITY')}:{' '}
										<span className="font-bold">
											{processingResults.mean_surface_velocity !== null
												? processingResults.mean_surface_velocity.toFixed(3)
												: '-'}{' '}
											m/s
										</span>
									</div>

									<div className="mb-3 font-light">
										{t('BULK_VELOCITY')}:{' '}
										<span className="font-bold">
											{processingResults.bulk_velocity !== null
												? processingResults.bulk_velocity.toFixed(3)
												: '-'}{' '}
											m/s
										</span>
									</div>

									<div className="mb-3 font-light">
										{t('WET_CROSS_SECTIONAL_AREA')}:{' '}
										<span className="font-bold">
											{processingResults.wet_cross_sectional_area !== null
												? processingResults.wet_cross_sectional_area.toFixed(3)
												: '-'}{' '}
											m/s
										</span>
									</div>

									<div className="mb-3 font-light">
										{t('DISPLACEMENT')}:{' '}
										<span className="font-bold">
											{processingResults.displacement !== null
												? processingResults.displacement.toFixed(3)
												: '-'}{' '}
											pix/s
										</span>
									</div>

									<Button
										onClick={confirmAndSaveMeasurement}
										className="max-w-xs normal-case"
										variant="contained"
										disabled={
											originalReprocessingData?.measurement_orginal.validation_status === 'APPROVED'
										}
										color="success"
										size="medium">
										{t('SAVE_MEASUREMENT')}
									</Button>
									<Button
										onClick={discardMeasurement}
										className="mt-2 max-w-xs normal-case"
										variant="contained"
										color="error"
										size="medium">
										{t('DISCARD')}
									</Button>
								</div>
							)}
						</div>
					</div>
					<div>
						<div className="mb-4 ml-2">{t('ORIGINAL_MEASUREMENT_RESULTS')}</div>
						<div className="flex  flex-row flex-wrap-reverse  ">
							<div className={'mr-4 flex flex-col items-center justify-center gap-4'}>
								<div className="relative ">
									<img
										id={'measImg'}
										ref={originalImgRef}
										src={cloudProcessing.rotatedImage?.imgSrc}
										onLoad={() => {
											if (
												!originalReprocessingData ||
												!originalCanvasRef.current ||
												!originalImgRef.current
											) {
												return;
											}

											onImageLoad();
										}}
										className={` ${containerWidthClass} max-w-full`}
										alt={t('MEASUREMENT_IMAGE')}
									/>
									<canvas className={`absolute top-0 left-0 h-full `} ref={originalCanvasRef} />
								</div>
							</div>
							{originalReprocessingData && originalReprocessingData.measurement_orginal && (
								<div className="mb-4 flex flex-col text-xl text-black">
									<div className="mb-3 font-light">
										{t('DISCHARGE')}:{' '}
										<span className="font-bold">
											{originalReprocessingData.measurement_orginal.discharge !== null
												? originalReprocessingData.measurement_orginal.discharge.toFixed(3)
												: '-'}{' '}
											m<sup>3</sup>/s
										</span>
									</div>

									<div className="mb-3 font-light">
										{t('WATER_COLUMN')}:{' '}
										<span className="font-bold">
											{originalReprocessingData.measurement_orginal.level !== null
												? originalReprocessingData.measurement_orginal.level.toFixed(3)
												: '-'}{' '}
											m
										</span>
									</div>
									<div className="mb-3 font-light">
										{t('MAX_SURFACE_VELOCITY')}:{' '}
										<span className="font-bold">
											{originalReprocessingData.measurement_orginal.velocity !== null
												? originalReprocessingData.measurement_orginal.velocity.toFixed(3)
												: '-'}{' '}
											m/s
										</span>
									</div>
									<div className="mb-3 font-light">
										{t('MEAN_SURFACE_VELOCITY')}:{' '}
										<span className="font-bold">
											{originalReprocessingData.measurement_orginal.mean_surface_velocity !== null
												? originalReprocessingData.measurement_orginal.mean_surface_velocity.toFixed(
														3
												  )
												: '-'}{' '}
											m/s
										</span>
									</div>

									<div className="mb-3 font-light">
										{t('BULK_VELOCITY')}:{' '}
										<span className="font-bold">
											{originalReprocessingData.measurement_orginal.bulk_velocity !== null
												? originalReprocessingData.measurement_orginal.bulk_velocity.toFixed(3)
												: '-'}{' '}
											m/s
										</span>
									</div>

									<div className="mb-3 font-light">
										{t('WET_CROSS_SECTIONAL_AREA')}:{' '}
										<span className="font-bold">
											{originalReprocessingData.measurement_orginal.wet_cross_sectional_area !==
											null
												? originalReprocessingData.measurement_orginal.wet_cross_sectional_area.toFixed(
														3
												  )
												: '-'}{' '}
											m/s
										</span>
									</div>

									<div className="mb-3 font-light">
										{t('DISPLACEMENT')}:{' '}
										<span className="font-bold">
											{originalReprocessingData.measurement_orginal.displacement !== null
												? originalReprocessingData.measurement_orginal.displacement.toFixed(3)
												: '-'}{' '}
											pix/s
										</span>
									</div>
									<Button
										onClick={discardMeasurement}
										className="mt-2 max-w-xs normal-case"
										variant="contained"
										color="error"
										size="medium">
										{t('DISCARD')}
									</Button>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default NormalProcessingResults;
