import { Handyman, InsertPhoto, OndemandVideo, Repeat } from '@mui/icons-material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import classNames from 'classnames';
import IconWithTooltip from 'components/shared/Tooltip/IconWithTooltip';
import { format } from 'date-fns';
import { capitalizeFirstLetter, convertToUsersTimezone } from 'helper/helperFunctions';
import { StationMeasurementsModel } from 'interfaces/models/StationMeasurementsModel';
import React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import PermissionView from 'components/shared/PermissionsView/PermissionView';
import { timezone_display } from 'rq/interfaces/User';

export const getGridColumns = (
	t: (param: string) => string,
	SIUnitsToggled: boolean,
	timezone_display: string,
	toggleMeasurementImageVideoModal: ({
		modalType,
		imageSrc,
		videoSrc,
		measurement
	}: {
		modalType: '' | 'video' | 'image';
		imageSrc: string;
		videoSrc: string;
		measurement?: StationMeasurementsModel | undefined;
	}) => void,
	reprocessMeasurement: (measId: number) => void
): GridColDef[] => {
	return [
		{
			field: 'measurement_id',
			headerName: t('MEASUREMENT_ID')
		},
		{
			field: 'date_time',
			headerName: `${t('TIME')}`,
			flex: 1,
			minWidth: 150,
			renderCell: ({ value }) => {
				return (
					<div>
						{format(
							convertToUsersTimezone(
								value,
								{ timezone_offset: value.utc_offset },
								timezone_display as timezone_display
							),
							'yyyy-MM-dd HH:mm'
						)}
					</div>
				);
			}
		},
		{
			field: 'discharge',
			headerName: `${t('DISCHARGE')}(Q) ${SIUnitsToggled ? '(m3/s)' : '(L/s)'}`,
			minWidth: 150,
			flex: 1,
			renderCell: ({ value }) => {
				if (value)
					return <div>{SIUnitsToggled ? value.toFixed(2) : (value * 1000).toFixed(2)}</div>;
				else return '';
			}
		},
		{
			field: 'discharge_q1',
			headerName: `${t('DISCHARGE')}(Q1) ${SIUnitsToggled ? '(m3/s)' : '(L/s)'}`,
			minWidth: 150,
			flex: 1,
			renderCell: ({ value }) => {
				if (value)
					return <div>{SIUnitsToggled ? value.toFixed(2) : (value * 1000).toFixed(2)}</div>;
				else return '';
			}
		},
		{
			field: 'discharge_q2',
			headerName: `${t('DISCHARGE')}(Q2) ${SIUnitsToggled ? '(m3/s)' : '(L/s)'}`,
			minWidth: 150,
			flex: 1,
			renderCell: ({ value }) => {
				if (value)
					return <div>{SIUnitsToggled ? value.toFixed(2) : (value * 1000).toFixed(2)}</div>;
				else return '';
			}
		},
		{
			field: 'discharge_q3',
			headerName: `${t('DISCHARGE')}(Q3) ${SIUnitsToggled ? '(m3/s)' : '(L/s)'}`,
			minWidth: 150,
			flex: 1,
			renderCell: ({ value }) => {
				if (value)
					return <div>{SIUnitsToggled ? value.toFixed(2) : (value * 1000).toFixed(2)}</div>;
				else return '';
			}
		},
		{
			field: 'level',
			headerName: `${t('LEVEL')} ${SIUnitsToggled ? '(m)' : '(cm)'}`,
			minWidth: 130,
			flex: 1,
			renderCell: ({ value }) => {
				if (value) return <div>{SIUnitsToggled ? value.toFixed(2) : (value * 100).toFixed(2)}</div>;
				else return '';
			}
		},
		{
			field: 'velocity',
			headerName: `${t('VELOCITY')} ${SIUnitsToggled ? '(m/s)' : '(cm/s)'}`,
			flex: 1,
			minWidth: 130,
			renderCell: ({ value }) => {
				if (value) return <div>{SIUnitsToggled ? value.toFixed(2) : (value * 100).toFixed(2)}</div>;
				else return '';
			}
		},
		{
			field: 'mean_surface_velocity',
			headerName: `${t('MEAN_SURFACE_VELOCITY')} ${SIUnitsToggled ? '(m/s)' : '(cm/s)'}`,
			flex: 1,
			minWidth: 130,
			renderCell: ({ value }) => {
				if (value) return <div>{SIUnitsToggled ? value.toFixed(2) : (value * 100).toFixed(2)}</div>;
				else return '';
			}
		},
		{
			field: 'bulk_velocity',
			headerName: `${capitalizeFirstLetter(t('BULK_VELOCITY'))} ${
				SIUnitsToggled ? '(m/s)' : '(cm/s)'
			}`,
			flex: 1,
			minWidth: 130,
			renderCell: ({ value }) => {
				if (value) return <div>{SIUnitsToggled ? value.toFixed(2) : (value * 100).toFixed(2)}</div>;
				else return '';
			}
		},
		{
			field: 'wet_cross_sectional_area',
			headerName: `${t('WET_CROSS_SECTIONAL_AREA')} ${SIUnitsToggled ? '(m2)' : '(cm2)'}`,
			flex: 1,
			minWidth: 150,
			renderCell: ({ value }) => {
				if (value) return <div>{SIUnitsToggled ? value.toFixed(2) : (value * 100).toFixed(2)}</div>;
				else return '';
			}
		},
		{
			field: 'displacement',
			headerName: `${t('DISPLACEMENT')} (pix/s)`,
			flex: 1,
			minWidth: 150
		},
		{
			hideable: true,
			field: 'camera_ok',
			headerName: `${t('CAMERA_OK')}`,
			flex: 1,
			minWidth: 150
		},
		{
			hideable: true,
			field: 'username',
			headerName: `${t('CREATED_BY')}`,
			flex: 1,
			minWidth: 150
		},
		{
			field: 'actions',
			headerName: `${t('ACTION')}`,
			filterable: false,
			minWidth: 150,
			disableColumnMenu: true,
			disableExport: true,
			sortable: false,
			hideable: false,
			editable: false,
			renderCell: (data) => {
				return (
					<div className={'flex flex-wrap gap-2'}>
						<div
							onClick={() =>
								data.row.image !== '' &&
								data.row.image &&
								toggleMeasurementImageVideoModal({
									modalType: 'image',
									imageSrc: data.row.image,
									videoSrc: '',
									measurement: data.row
								})
							}>
							<IconWithTooltip
								title={`${t('MEASUREMENT_PHOTO')}`}
								icon={
									<InsertPhoto
										className={classNames(
											{ 'text-secondary': data.row.image !== '' && data.row.image },
											{
												'cursor-auto text-gray-300 hover:opacity-100':
													data.row.image === '' || data.row.image === null
											}
										)}
									/>
								}
							/>
						</div>
						<div
							onClick={() =>
								data.row.video_url !== '' &&
								data.row.video_url !== null &&
								toggleMeasurementImageVideoModal({
									modalType: 'video',
									imageSrc: '',
									videoSrc: data.row.video_url
								})
							}>
							<IconWithTooltip
								title={`${t('MEASUREMENT_VIDEO')}`}
								icon={
									<OndemandVideo
										className={classNames(
											{
												'text-secondary': data.row.video_url !== '' && data.row.video_url !== null
											},
											{
												'cursor-auto text-gray-300 hover:opacity-100':
													data.row.video_url === '' || !data.row.video_url
											}
										)}
									/>
								}
							/>
						</div>
						<PermissionView
							requiredPermissions={'validate_measurements'}
							showFallbackComponent={false}>
							<>
								{data.row.validation_status !== 'APPROVED' && (
									<IconWithTooltip
										title={`${t('VALIDATE_MEASUREMENT')}`}
										icon={<AssignmentIcon className={'text-secondary'} />}
									/>
								)}
							</>
						</PermissionView>
						<>
							<IconWithTooltip
								title={`${t('DEBUG_DATA')}`}
								icon={
									<a
										href={data.row.debug_data_url !== '' ? data.row.debug_data_url : undefined}
										target={'_blank'}
										rel="noreferrer noopener">
										<Handyman
											className={classNames(
												{ 'text-secondary': data.row.debug_data_url !== '' },
												{
													'cursor-not-allowed text-gray-300 ': data.row.debug_data_url === ''
												}
											)}
										/>
									</a>
								}
							/>
						</>
						<div
							onClick={() =>
								data.row.video_url !== '' &&
								data.row.device_orientation.length > 0 &&
								reprocessMeasurement(data.row.measurement_id)
							}>
							<IconWithTooltip
								title={`${t('REPROCESS_MEASUREMENT')}`}
								icon={
									<Repeat
										className={classNames(
											{
												'text-secondary':
													data.row.video_url !== '' && data.row.device_orientation.length > 0
											},
											{
												'cursor-not-allowed text-gray-300 hover:opacity-100':
													data.row.video_url === '' || data.row.device_orientation.length === 0
											}
										)}
									/>
								}
							/>
						</div>
					</div>
				);
			}
		}
	];
};
