import { LinearProgress } from '@mui/material';
import classNames from 'classnames';
import SidebarSitesList from 'components/SidebarList/SidebarSitesList';
import { useGlobalStore } from 'global-state/useStore';
import MapContent from 'pages/Map/MapContent';
import ToggleClusterControl from 'pages/Map/ToggleClusterControl';
import React, { useEffect, useRef, useState } from 'react';
import { MapContainer, TileLayer, ZoomControl } from 'react-leaflet';
import { LatLngTuple } from 'leaflet';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { useLocation } from 'react-router-dom';
import { useGetAllSites, useGetLastMeasurements } from 'rq/hooks/sitesHook';

const position = [45.8047, 15.9788] as LatLngTuple;

const MapPage = () => {
	const [sidebarOpen, setSidebarOpen] = useState(false);
	const setSelectedSiteId = useGlobalStore((state) => state.setSelectedSiteId);
	const mapRef = useRef<L.Map>(null);
	const location = useLocation();

	const locationState = location.state as
		| {
				latitude: number;
				longitude: number;
				site_id: number;
		  }
		| undefined;

	const { isLoading: sitesLoading } = useGetAllSites();

	useGetLastMeasurements({
		last_measurements: true,
		sub: false
	});

	const handleSiteClick = ({ siteId, closeSidebar }: { siteId: number; closeSidebar: boolean }) => {
		closeSidebar && setSidebarOpen(!sidebarOpen);
		setSelectedSiteId(siteId);
	};

	useEffect(() => {
		if (locationState === null) {
			setSelectedSiteId(null);
		}
	}, []);

	useEffect(() => {
		locationState?.site_id && setSelectedSiteId(locationState.site_id);
		setTimeout(() => {
			locationState &&
				mapRef.current &&
				!isNaN(locationState.latitude) &&
				!isNaN(locationState.longitude) &&
				mapRef.current.flyTo([locationState.latitude, locationState.longitude], 21, {
					duration: 5
				});
		}, 100);
	}, [locationState]);

	return (
		<>
			<div className={'flex h-full w-full flex-row'}>
				<div className={'absolute right-2 z-10 mt-2 text-accent'}>
					<div
						onClick={() => setSidebarOpen(!sidebarOpen)}
						className={'cursor-pointer rounded-full'}>
						{sidebarOpen ? (
							<ArrowCircleRightIcon className={'h-12 w-12'} />
						) : (
							<ArrowCircleLeftIcon className={'h-12 w-12'} />
						)}
					</div>
				</div>
				<div
					className={classNames(
						{ 'leaflet-container relative': true },
						{
							'max-md:hidden md:w-1/2 lg:w-2/3 ': sidebarOpen
						}
					)}>
					{sitesLoading && (
						<LinearProgress className={'absolute z-20 h-2 w-full'} color={'error'}></LinearProgress>
					)}
					<MapContainer
						center={position}
						zoom={3}
						ref={mapRef}
						scrollWheelZoom={true}
						bounceAtZoomLimits={true}
						zoomControl={false}
						minZoom={3}>
						<TileLayer
							attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
							url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png"
							maxZoom={25}
							maxNativeZoom={21}
						/>
						<ZoomControl position={'bottomright'} />
						<ToggleClusterControl />

						<MapContent></MapContent>
					</MapContainer>
				</div>
				{sidebarOpen && (
					<div className={' flex flex-1 flex-col md:w-1/2 md:pr-2 md:pl-2 md:pb-2 lg:w-1/3'}>
						<SidebarSitesList mapRef={mapRef ?? null} handleSiteClick={handleSiteClick} />
					</div>
				)}
			</div>
		</>
	);
};
export default MapPage;
