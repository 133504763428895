import SearchIcon from '@mui/icons-material/Search';
import GlobalSearch from 'components/GlobalSearch/GlobalSearch';
import { useGlobalStore } from 'global-state/useStore';
import React, { FunctionComponent, useEffect, useState } from 'react';
import Toolbar from '@mui/material/Toolbar';
import { Backdrop, Breadcrumbs, ClickAwayListener, IconButton, Typography } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import DomainIcon from '@mui/icons-material/Domain';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { useTranslation } from 'react-i18next';
import { useAuth, useLogout } from 'rq/hooks/authHook';
import { useOrganizationDetails } from 'rq/hooks/organizationsHook';

import { AppBarProps } from './types';
import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import OrganizationSwitcher from '../Modal/OrganizationSwitcher/OrganizationSwitcher';
import ProfileMenu from './ProfileMenu';
import SmallScreensMenu from './SmallScreensMenu';
import Chip from '@mui/material/Chip';
import { displayUserFullName } from 'helper/helperFunctions';
import useReactRouterBreadcrumbs from 'use-react-router-breadcrumbs';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { displayBreadCrumbText } from 'helper/helperFunctions';

const AppBarComponent = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open'
})<AppBarProps>(({ theme }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen
	})
}));

const AppBar: FunctionComponent<AppBarProps> = (props) => {
	const { open, drawerToggle } = props;
	const navigate = useNavigate();
	const { t } = useTranslation();
	const location = useLocation();

	const theme = useTheme();

	const largeBreakpoint = useMediaQuery(theme.breakpoints.up(1024));
	const clickAwayClosesMenu = useMediaQuery(theme.breakpoints.down(768));
	const breadcrumbs = useReactRouterBreadcrumbs();
	breadcrumbs.splice(1, 1);

	const { data: user } = useAuth();
	const { mutate: logout } = useLogout();

	const currentOrganizationId = useGlobalStore((state) => state.currentOrganization.id);

	const { data: currentOrganization } = useOrganizationDetails(
		currentOrganizationId as unknown as string
	);

	const userImage = user?.image;
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [anchorElMenu, setAnchorElMenu] = useState<null | HTMLElement>(null);

	const openUserMenu = Boolean(anchorEl);
	const openMenu = Boolean(anchorElMenu);

	const toggleGlobalSearchBar = useGlobalStore((state) => state.toggleGlobalSearchBar);
	const toggleOrganizationSwitchModal = useGlobalStore(
		(state) => state.toggleOrganizationSwitchModal
	);
	const organizationSwitcher = useGlobalStore((state) => state.organizationSwitcher);
	const toggleConfirmationDialog = useGlobalStore((state) => state.toggleConfirmationDialog);

	useEffect(() => {
		if (organizationSwitcher.toggled && organizationSwitcher.organizations?.length === 1) {
			const org = organizationSwitcher.organizations[0];
			toggleConfirmationDialog({
				dialogOpen: true,
				message: t('SHOW_SITE_DETAILS_PROMPT', { orgName: org.name }),
				type: 'confirmation',
				confirmActionLabel: t('SWITCH_ORG_AND_SHOW_SITE'),
				cancelActionLabel: t('CANCEL'),
				confirmAction: () => navigate(`/${org.id}`)
			});
			toggleOrganizationSwitchModal({ organizations: null, toggled: false });
		}
	}, [organizationSwitcher.toggled, organizationSwitcher.organizations]);
	const handleModalOpen = () =>
		toggleOrganizationSwitchModal({ toggled: true, organizations: null });
	const handleModalClose = () =>
		toggleOrganizationSwitchModal({ toggled: false, organizations: null });

	const logoutHandler = () => {
		logout();
	};

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleSmallScreenMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElMenu(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleMenuClose = () => {
		setAnchorElMenu(null);
	};

	return (
		<>
			{clickAwayClosesMenu && (
				<Backdrop open={open !== undefined ? open : false} className={'z-10'} />
			)}
			<AppBarComponent open={open} className={'bg-secondary'} position={'relative'}>
				<Toolbar className=" flex h-max min-h-[4rem] justify-between">
					<ClickAwayListener
						onClickAway={() => {
							drawerToggle && open && clickAwayClosesMenu && drawerToggle();
						}}>
						<IconButton
							edge="start"
							color="inherit"
							aria-label="open drawer"
							className={'mr-4 text-primary'}
							onClick={drawerToggle}>
							{open ? <MenuOpenIcon /> : <MenuIcon />}
						</IconButton>
					</ClickAwayListener>
					<Typography className={'flex grow '} component={'span'}>
						<Breadcrumbs
							separator={<NavigateNextIcon className={'text-xs text-primary lg:text-lg'} />}
							maxItems={largeBreakpoint ? 4 : 2}
							aria-label={'breadcrumb'}>
							{breadcrumbs.map(({ match, breadcrumb }, index) => {
								return (
									<div key={index} className={'max-w-[8rem] truncate lg:max-w-max '}>
										<NavLink
											to={match.pathname}
											className={
												'text-xs font-bold text-primary hover:underline md:text-sm lg:text-lg'
											}
											key={`breadcrumb-${index}`}>
											{location.state?.user || user
												? displayBreadCrumbText(match.pathname, location.state?.user ?? user) ??
												  breadcrumb
												: breadcrumb}
										</NavLink>
									</div>
								);
							})}
						</Breadcrumbs>
					</Typography>
					<div className={'flex gap-3'}>
						<div className={'flex flex-row items-center justify-self-end'}>
							{largeBreakpoint ? (
								<GlobalSearch />
							) : (
								<SearchIcon
									onClick={() =>
										toggleGlobalSearchBar({ toggled: true, searchType: 'in_organization' })
									}
									className={'text-primary'}
								/>
							)}
						</div>
						{largeBreakpoint ? (
							<>
								<Chip
									onClick={handleModalOpen}
									variant="outlined"
									className=" h-10 rounded-[20px] bg-primary px-1 text-secondary hover:opacity-90"
									icon={
										currentOrganization?.logo ? (
											<Avatar className="mx-0  h-8 w-8" src={currentOrganization.logo} />
										) : (
											<Avatar className="mx-0 h-8 w-8 bg-secondary">
												<DomainIcon className={'text-primary'} />
											</Avatar>
										)
									}
									label={currentOrganization?.name}
								/>
								<Chip
									onClick={handleClick}
									variant="outlined"
									className="h-10 rounded-[20px] bg-primary px-1 text-secondary hover:opacity-90"
									icon={
										<Avatar
											className="mx-0  h-8 w-8 bg-secondary text-primary"
											alt="user"
											src={userImage}
										/>
									}
									label={user && displayUserFullName(user)}
								/>
								<ProfileMenu
									anchorEl={anchorEl}
									openUserMenu={openUserMenu}
									handleClose={handleClose}
									logoutHandler={logoutHandler}
								/>
							</>
						) : (
							<>
								<IconButton onClick={handleSmallScreenMenu}>
									<MoreVertIcon className="text-primary" />
								</IconButton>
								<SmallScreensMenu
									anchorEl={anchorElMenu}
									openMenu={openMenu}
									handleClose={handleMenuClose}
									logoutHandler={logoutHandler}
									handleModalOpen={handleModalOpen}
								/>
							</>
						)}
					</div>
				</Toolbar>
			</AppBarComponent>
			{organizationSwitcher.toggled && (
				<OrganizationSwitcher
					open={organizationSwitcher.toggled}
					handleModalClose={handleModalClose}
				/>
			)}
		</>
	);
};
export default AppBar;
