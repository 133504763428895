import { Close } from '@mui/icons-material';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import { IModalProps } from 'components/shared/Modal/types';
import React, { FunctionComponent } from 'react';
import { default as MUIModal } from '@mui/material/Modal';

const Modal: FunctionComponent<IModalProps> = ({ children, open, closeModal, modalTitle }) => {
	return (
		<>
			<MUIModal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				open={open}
				onClose={closeModal}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 1000
				}}
				className="shadow-xl">
				<Fade in={open}>
					<Box className="absolute top-1/2 left-1/2  -translate-x-1/2 -translate-y-1/2 transform bg-primary shadow-xl shadow-gray-900 outline-none max-md:h-full max-md:w-full md:min-w-[40rem] md:max-w-[90%] ">
						{modalTitle !== '' && (
							<div className="flex w-full justify-between bg-secondary py-4 px-2 text-xl font-medium text-white">
								<span>{modalTitle}</span>
								<div className="cursor-pointer" onClick={closeModal}>
									<Close />
								</div>
							</div>
						)}
						<div className="max-h-[90vh] overflow-y-auto">{children}</div>
					</Box>
				</Fade>
			</MUIModal>
		</>
	);
};
export default Modal;
