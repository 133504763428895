import { Button } from '@mui/material';
import Modal from 'components/shared/Modal/Modal';
import { useGlobalStore } from 'global-state/useStore';
import { DischargeKeeperStationDetails } from 'interfaces/models/DischargeKeeperStationDetails';
import { DischargeStationDetails } from 'interfaces/models/DischargeStationDetails';
import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useStationDetails, useUpdateStation } from 'rq/hooks/stationHook';
import { selectQhFile } from 'utils/freeProfileParamsHelper';
import { handleFileReadAsString } from 'utils/functions';

const EditCrossSectionPointsModal = () => {
	const { t } = useTranslation();

	const uploadInputRef = useRef<HTMLInputElement | null>(null);

	const toggleEditFreeProfilePointsModal = useGlobalStore(
		(state) => state.toggleEditFreeProfilePointsModal
	);
	const selectedSiteId = useGlobalStore((state) => state.selectedSiteId);
	const { open, station_id } = useGlobalStore((state) => state.editFreeProfilePointsModal);

	const { data: stationDetails } = useStationDetails<
		DischargeStationDetails | DischargeKeeperStationDetails
	>(Number(selectedSiteId), Number(station_id));

	const { mutate: updateStation } = useUpdateStation();

	const handleModalClose = () => {
		toggleEditFreeProfilePointsModal({
			open: false,
			station_id: undefined
		});
	};

	const [crossSectionPointsDefined, setCrossSectionPointsDefined] = useState(
		stationDetails &&
			stationDetails.profile.free_params &&
			Math.floor(stationDetails.profile.free_params.length / 2)
	);

	const [file, setFile] = useState({ content: '' });

	useEffect(() => {
		setCrossSectionPointsDefined(
			stationDetails &&
				stationDetails.profile.free_params &&
				Math.floor(stationDetails.profile.free_params.length / 2)
		);
	}, [stationDetails]);

	useEffect(() => {
		const fileArray = file.content ? file.content.split(',') : [];
		file.content !== '' && setCrossSectionPointsDefined(Math.floor(fileArray.length / 2));
	}, [file]);

	const readUploadedFile = (content: string) => {
		setFile({ content: selectQhFile(content) ?? '' });
	};
	const handleUploadClick = () => {
		uploadInputRef.current?.click();
	};

	const handleCrossSectionUpdate = () => {
		const data = { ...(stationDetails as DischargeStationDetails | DischargeKeeperStationDetails) };
		if (file.content !== '') {
			const profile = file.content ? file.content.split(',') : [];

			data.profile.free_params = profile.map(Number);
		}

		if ('camera_angle' in data) {
			data.camera_angle = data.camera_angle ?? 0;
			data.camera_rotation = data.camera_rotation.length ? data.camera_rotation : [0];
			data.camera_position = data.camera_rotation.length ? data.camera_rotation : [0];
		}

		updateStation(
			{
				siteId: Number(selectedSiteId),
				stationId: Number(station_id),
				data: data
			},
			{
				onSuccess: () => {
					toast.success(t('STATION_UPDATED'));
					toggleEditFreeProfilePointsModal({ open: false, station_id: undefined });
				},
				onError: (err: any) => {
					toast.error(err.details);
				}
			}
		);
	};

	return (
		<Modal closeModal={handleModalClose} open={open} modalTitle={t('CROSS_SECTION')}>
			<div className={'flex max-w-[40rem] flex-1 flex-col gap-10 p-4'}>
				<input
					type="file"
					id={`edit-cross-section-input`}
					className={'hidden'}
					ref={uploadInputRef}
					onChange={(e) => {
						handleFileReadAsString(e, readUploadedFile);
					}}
					accept="*"
				/>
				<div>{`${crossSectionPointsDefined} ${t('POINTS_DEFINED')}`}</div>
				<div>{t('FREE_PROFILE_PARAMETERS_NOTE')}</div>
				<div className={'flex flex-1 justify-center gap-8'}>
					<Button
						variant={'contained'}
						className={'bg-accent normal-case'}
						onClick={handleUploadClick}>
						{t('IMPORT_FROM_FILE')}
					</Button>
					<Button
						variant={'contained'}
						className={'normal-case'}
						onClick={handleCrossSectionUpdate}>
						{t('UPDATE')}
					</Button>
				</div>
			</div>
		</Modal>
	);
};
export default EditCrossSectionPointsModal;
