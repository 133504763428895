import { RefObject, useEffect } from 'react';
import { drawWaterLine } from 'utils/canvasFunctions';

export const useCanvasDrawing = (
	imgRef: RefObject<HTMLImageElement>,
	canvasRef: RefObject<HTMLCanvasElement>,
	imgScale: { imgScaleX: number; imgScaleY: number },
	waterLineData: any
) => {
	useEffect(() => {
		const drawWaterLevel = () => {
			if (!waterLineData || !canvasRef.current || !imgRef.current) return;
			const ctx = canvasRef.current.getContext('2d');
			if (!ctx) return;
			ctx.clearRect(0, 0, ctx?.canvas.width, ctx?.canvas.height);
			ctx.beginPath();
			ctx.lineWidth = 2;
			ctx.strokeStyle = 'red';
			if (waterLineData.i.length === 4) {
				drawWaterLine(waterLineData.i, ctx, imgScale.imgScaleX, imgScale.imgScaleY);
			}
			for (let i = 0; i < waterLineData.i.length; i = i + 1) {
				ctx.lineTo(
					waterLineData.j[i] / imgScale.imgScaleX,
					waterLineData.i[i] / imgScale.imgScaleY
				);
				ctx.stroke();
			}
			ctx.lineTo(waterLineData.j[1079], waterLineData.i[1079]);
			ctx.stroke();
			canvasRef.current.style.top = '0px';
		};

		drawWaterLevel();
	}, [waterLineData, imgScale, imgRef.current, canvasRef]);
};
