import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import AddFilterModal from 'components/Modal/AddFilterModal/AddFilterModal';
import AlarmsCreateUpdateModal from 'components/Modal/AlarmsCreateUpdateModal';
import ConfigHistoricalOverviewModal from 'components/Modal/ConfigHistoricalOverview/ConfigHistoricalOverviewModal';
import GlobalSearchModal from 'components/Modal/GlobalSearch/GlobalSearchModal';
import MeasurementImageAndVideoModal from 'components/Modal/MeasurementImageVideo';
import MoveOrShareSiteModal from 'components/Modal/MoveOrShareSite';
import OrganizationJoinOrRedirect from 'components/Modal/Organization/OrganizationJoinOrRedirect';
import ChangeSiteLocationModal from 'components/Modal/Site/ChangeSiteLocationModal';
import CreateNewSiteModal from 'components/Modal/Site/CreateNewSite';
import SiteImageModal from 'components/Modal/Site/SiteImageModal';
import CreateStationModal from 'components/Modal/Station/CreateStationModal';
import EditCrossSectionPointsModal from 'components/Modal/Station/CrossSection/EditCrossSectionPointsModal';
import FreeProfileChartModal from 'components/Modal/Station/CrossSection/FreeProfileChartModal';
import SetUpCrossSectionModal from 'components/Modal/Station/CrossSection/SetUpCrossSectionModal';
import EditAdvancedConfigModal from 'components/Modal/Station/EditAdvancedConfig';
import EditCustomConfigModal from 'components/Modal/Station/EditCustomConfig';
import EditGCP from 'components/Modal/Station/GCP/EditGCP';
import EditQHDataModal from 'components/Modal/Station/RatingCurve/EditQHDataModal';
import { useGlobalStore } from 'global-state/useStore';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ModalContainer = () => {
	const { t } = useTranslation();

	const theme = useTheme();
	const largeBreakpoint = useMediaQuery(theme.breakpoints.up(1024));

	const moveOrShareSiteModal = useGlobalStore((state) => state.moveOrShareSiteModal);
	const toggleMoveOrShareSiteModal = useGlobalStore((state) => state.toggleMoveOrShareSiteModal);
	const globalSearchToggled = useGlobalStore((state) => state.globalSearchToggled);
	const toggleGlobalSearchBar = useGlobalStore((state) => state.toggleGlobalSearchBar);
	const createStationModalOpen = useGlobalStore((state) => state.createStationModalOpen);
	const toggleCreateStationModal = useGlobalStore((state) => state.toggleCreateStationModal);
	const changeSiteLocationModalOpen = useGlobalStore((state) => state.changeSiteLocationModalOpen);
	const siteImageModalOpen = useGlobalStore((state) => state.siteImageModalOpen);
	const measurementsFilterModalOpen = useGlobalStore((state) => state.measurementsFilterModalOpen);
	const measurementImageVideoModal = useGlobalStore((state) => state.measurementImageVideoModal);
	const createNewSiteModal = useGlobalStore((state) => state.createNewSiteModal);
	const editGCPModal = useGlobalStore((state) => state.editGCPModal);
	const freeProfileChartModal = useGlobalStore((state) => state.freeProfileChartModal);
	const editFreeProfilePointsModal = useGlobalStore((state) => state.editFreeProfilePointsModal);
	const editCustomConfig = useGlobalStore((state) => state.editCustomConfig);
	const editQHDataModal = useGlobalStore((state) => state.editQHDataModal);
	const editAdvancedConfig = useGlobalStore((state) => state.editAdvancedConfig);
	const configHistoricalOverview = useGlobalStore((state) => state.configHistoricalOverview);
	const setUpCrossSectionModal = useGlobalStore((state) => state.setUpCrossSectionModal);
	const joinOrRedirectOrganizationModal = useGlobalStore(
		(state) => state.joinOrRedirectOrganizationModal
	);
	const createUpdateAlarmModalOpen = useGlobalStore((state) => state.createUpdateAlarmModal.open);

	return (
		<>
			{moveOrShareSiteModal.moveOrShareSiteModalOpen && (
				<MoveOrShareSiteModal
					open={moveOrShareSiteModal.moveOrShareSiteModalOpen}
					closeModal={toggleMoveOrShareSiteModal}
					modalTitle={t('MOVE_OR_SHARE_SITE')}
				/>
			)}
			{createStationModalOpen && (
				<CreateStationModal open={createStationModalOpen} handleClose={toggleCreateStationModal} />
			)}
			{changeSiteLocationModalOpen && <ChangeSiteLocationModal />}{' '}
			{createNewSiteModal.open && <CreateNewSiteModal />} {siteImageModalOpen && <SiteImageModal />}
			{measurementsFilterModalOpen && <AddFilterModal />}
			{measurementImageVideoModal.open && <MeasurementImageAndVideoModal />}
			{editGCPModal.open && <EditGCP />} {freeProfileChartModal.open && <FreeProfileChartModal />}
			{editFreeProfilePointsModal.open && <EditCrossSectionPointsModal />}
			{editCustomConfig.open && <EditCustomConfigModal />}
			{editQHDataModal.open && <EditQHDataModal />}
			{globalSearchToggled.toggled && !largeBreakpoint && (
				<div className={'lg:hidden'}>
					<GlobalSearchModal
						open={globalSearchToggled.toggled}
						closeModal={() =>
							toggleGlobalSearchBar({
								toggled: false,
								searchType: null
							})
						}
						modalTitle={t('SEARCH_EVERYWHERE')}
					/>
				</div>
			)}
			{editAdvancedConfig.open && <EditAdvancedConfigModal />}
			{configHistoricalOverview.open && <ConfigHistoricalOverviewModal />}
			{setUpCrossSectionModal.open && <SetUpCrossSectionModal />}
			{joinOrRedirectOrganizationModal.open && <OrganizationJoinOrRedirect />}
			{createUpdateAlarmModalOpen && <AlarmsCreateUpdateModal />}
		</>
	);
};
export default ModalContainer;
