import { GroupWork } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useGlobalStore } from 'global-state/useStore';
import L from 'leaflet';
import { createRoot } from 'react-dom/client';
import { useMap } from 'react-leaflet';
import React, { useEffect } from 'react';

class LeafletControl extends L.Control {
	toggleMethod: () => void;

	constructor(options = {}, toggleMethod: () => void) {
		super(options);
		this.toggleMethod = toggleMethod;
	}

	onAdd(): HTMLElement {
		const container = L.DomUtil.create('div', 'leaflet-control-layers leaflet-control');

		L.DomEvent.disableClickPropagation(container);

		const root = createRoot(container);

		root.render(
			<IconButton onClick={this.toggleMethod} style={{ backgroundColor: '#F4F2F3' }} size="medium">
				<GroupWork />
			</IconButton>
		);

		return container;
	}
}

const ToggleClusterControl = () => {
	const map = useMap();
	const toggleMapCluster = useGlobalStore((state) => state.toggleMapCluster);

	useEffect(() => {
		const control = new LeafletControl({ position: 'bottomright' }, toggleMapCluster);

		control.addTo(map);

		return () => {
			map.removeControl(control);
		};
	}, [map, toggleMapCluster]);

	return null;
};

export default ToggleClusterControl;
