import { endOfDay, startOfDay, sub } from 'date-fns';
import { DischargeKeeperStationDetails } from 'interfaces/models/DischargeKeeperStationDetails';
import { DischargeStationCurve } from 'interfaces/models/DischargeStationCurve';
import { DischargeStationDetails } from 'interfaces/models/DischargeStationDetails';
import { SiteAlarmRetrieve } from 'interfaces/models/SiteAlarmRetrieve';
import { StationMeasurementsModel } from 'interfaces/models/StationMeasurementsModel';
import { PROCESSING_STEPS, ProcessingStep } from 'pages/CloudProcessing/ProcessingSteps';
import { timezone_display } from 'rq/interfaces/User';
import {
	BaseCloudProcessingConfig,
	CalibrationResponse,
	ReprocessingMeasurementsResponse
} from 'services/CloudProcessingService/types';
import { MeasurementFilter, ParsedFilter } from 'utils/helperData';
import create from 'zustand';
import { MembershipPermissionCreateUpdate } from 'interfaces/models/MembershipPermissionCreateUpdate';
import { UserListRetrieve } from 'interfaces/models/UserListRetrieve';

interface GlobalState {
	currentOrganization: {
		id: number;
		permissions: MembershipPermissionCreateUpdate | null | undefined;
		name: string | null;
	};
	confirmationDialog: null | {
		dialogOpen: boolean;
		headerTitle?: string;
		message: string;
		confirmAction: () => void;
		type: 'info' | 'confirmation' | null;
		confirmActionLabel?: string;
		cancelActionLabel?: string;
	};
	inviteUsersModal: {
		inviteUsersModalOpen: boolean;
		selectedUser: UserListRetrieve | null;
		userPermissions: MembershipPermissionCreateUpdate | null;
	};
	moveOrShareSiteModal: {
		moveOrShareSiteModalOpen: boolean;
		selectedSiteId: number | null;
	};
	toggleInviteUsersModal: (inviteUsersInfo: {
		inviteUsersModalOpen: boolean;
		selectedUser: UserListRetrieve | null;
		userPermissions: MembershipPermissionCreateUpdate | null;
	}) => void;
	toggleConfirmationDialog: (confirmationDialog: {
		dialogOpen: boolean;
		headerTitle?: string;
		message: string;
		confirmAction: () => void;
		type: 'info' | 'confirmation' | null;
		confirmActionLabel?: string;
		cancelActionLabel?: string;
	}) => void;
	toggleMoveOrShareSiteModal: (siteId?: number) => void;
	globalSearchToggled: {
		toggled: boolean;
		searchType: 'in_organization' | 'everywhere' | null;
	};
	toggleGlobalSearchBar: (globalSearchBar: {
		toggled: boolean;
		searchType: 'in_organization' | 'everywhere' | null;
	}) => void;
	toggleOrganizationSwitchModal: (organizationSwitcher: {
		toggled: boolean;
		organizations: any;
	}) => void;
	organizationSwitcher: {
		toggled: boolean;
		organizations: any;
	};
	selectedSiteId: number | null;
	setSelectedSiteId: (siteId: number | null) => void;

	createStationModalOpen: boolean;
	toggleCreateStationModal: () => void;
	changeSiteLocationModalOpen: boolean;
	toggleChangeSiteLocationModal: () => void;
	siteImageModalOpen: boolean;
	toggleSiteImageModal: () => void;
	measurementsFilterModalOpen: boolean;
	toggleMeasurementsFilterModal: () => void;
	selectedMeasurementFilters: MeasurementFilter[];
	setSelectedMeasurementFilter: (selectedFilters: MeasurementFilter[]) => void;
	appliedMeasurementFilters: MeasurementFilter[];
	setAppliedMeasurementFilter: (selectedFilters: MeasurementFilter[]) => void;
	parsedFilters: ParsedFilter[] | undefined;
	setParsedFilters: (filters: ParsedFilter[]) => void;
	selectedMeasurementId: number | undefined;
	setSelectedMeasurementId: (measurement_id: number | undefined) => void;
	measurementImageVideoModal: {
		open: boolean;
		modalType: '' | 'image' | 'video';
		imageSrc: string;
		videoSrc: string;
		measurement?: StationMeasurementsModel;
	};
	toggleMeasurementImageVideoModal: ({
		modalType,
		imageSrc,
		videoSrc,
		measurement
	}: {
		modalType: '' | 'image' | 'video';
		imageSrc: string;
		videoSrc: string;
		measurement?: StationMeasurementsModel;
	}) => void;

	exportMeasurementsModal: {
		open: boolean;
		export_timelapse: boolean;
	};
	toggleExportMeasurementsModal: ({
		open,
		export_timelapse
	}: {
		open: boolean;
		export_timelapse?: boolean;
	}) => void;

	measurementsCalendar: {
		open: boolean;
		date_from: Date | undefined;
		date_to: Date | undefined;
		key: string;
	};

	toggleMeasurementsCalendar: ({
		open,
		date_from,
		date_to,
		key
	}: {
		open: boolean;
		date_from: Date | undefined;
		date_to: Date | undefined;
		key: string;
	}) => void;

	createNewSiteModal: {
		open: boolean;
	};

	toggleCreateNewSiteModal: () => void;

	editGCPModal: {
		open: boolean;
		station_id: number | null;
		stationDetails: DischargeStationDetails | DischargeKeeperStationDetails | null;
	};
	toggleEditGCPModal: ({
		open,
		station_id,
		stationDetails
	}: {
		open: boolean;
		station_id: number | null;
		stationDetails: DischargeStationDetails | DischargeKeeperStationDetails | null;
	}) => void;
	setUpCrossSectionModal: {
		open: boolean;
		station_id: number | null;
	};
	toggleSetUpCrossSectionModal: ({
		open,
		station_id
	}: {
		open: boolean;
		station_id: number | null;
	}) => void;
	profileCalibration: {
		profile: number[];
		markers: number[];
		base64Img: string;
	};
	setCalibrationImg: (img: string, profile: number[], markers: number[]) => void;
	freeProfileChartModal: {
		open: boolean;
		station_id: number | undefined;
	};
	toggleFreeProfileChartModal: ({
		open,
		station_id
	}: {
		open: boolean;
		station_id: number | undefined;
	}) => void;
	editFreeProfilePointsModal: {
		open: boolean;
		station_id: number | undefined;
	};
	toggleEditFreeProfilePointsModal: ({
		open,
		station_id
	}: {
		open: boolean;
		station_id: number | undefined;
	}) => void;
	SIUnitsToggled: boolean;
	toggleSIUnits: () => void;
	editCustomConfig: {
		open: boolean;
		station_id: number | null;
	};
	toggleEditCustomConfig: (open: boolean, stationId: number | null) => void;
	editAdvancedConfig: {
		open: boolean;
		station_id: number | null;
	};
	toggleEditAdvancedConfig: (open: boolean, stationId: number | null) => void;
	ratingCurveData: {
		station_id: number | null;
		site_id: number | null;
		ratingCurves: DischargeStationCurve[] | undefined;
	};
	setRatingCurveData: ({
		station_id,
		site_id,
		ratingCurves
	}: {
		station_id: number;
		site_id: number;
		ratingCurves: DischargeStationCurve[] | undefined;
	}) => void;
	ratingCurveFormData: {
		rating_curve: DischargeStationCurve | null;
		formToggled: boolean;
	};
	setRatingCurveFormData: ({
		rating_curve,
		formToggled
	}: {
		rating_curve: DischargeStationCurve | null;
		formToggled: boolean;
	}) => void;

	editQHDataModal: {
		open: boolean;
	};
	toggleEditQHDataModal: () => void;
	userInfo: {
		timezone_display: timezone_display;
	};
	setUserInfo: ({ timezone_display }: { timezone_display: timezone_display }) => void;
	hostname: {
		isSeba: boolean;
		isDefault: boolean;
	};
	setHostname: (hostname: string) => void;
	mapClusterToggled: boolean;
	toggleMapCluster: () => void;
	configHistoricalOverview: {
		open: boolean;
		stationId: number | null;
		siteId: number | null;
	};
	toggleConfigHistoricalOverview: (
		open: boolean,
		stationId: number | null,
		siteId: number | null
	) => void;
	joinOrRedirectOrganizationModal: {
		open: boolean;
		organizationId: number | null;
		orgName: string;
		error: boolean;
	};
	toggleJoinOrRedirectOrganizationModal: (
		open: boolean,
		organizationId: number | null,
		orgName: string,
		error: boolean
	) => void;
	// Cloud processing part

	cloudProcessing: {
		config?: BaseCloudProcessingConfig;
		configManuallyUploaded?: boolean;
		video?: {
			video: string;
			first_frame: string;
			processing_id: string;
		};
		rotatedImage?: {
			imgSrc: string;
			rotation?: number;
			imgOrientation?: 'horizontal' | 'vertical';
			imgWidth?: number;
			imgHeight?: number;
			scaledX?: number;
			scaledY?: number;
		};
		calibrationResults?: CalibrationResponse['calibration_results'];
	};

	setCurrentOrganization: (
		org: {
			id: number;
			permissions: MembershipPermissionCreateUpdate | undefined;
			name: string;
		},
		userId: number
	) => void;

	setCloudProcessingVideo: ({
		video
	}: {
		video: string;
		first_frame: string;
		processing_id: string;
	}) => void;

	setCloudProcessingRotatedImage: ({
		imgSrc,
		rotation,
		imgOrientation,
		imgHeight,
		imgWidth,
		scaledX,
		scaledY
	}: {
		imgSrc?: string;
		rotation?: number;
		imgOrientation?: 'horizontal' | 'vertical';
		imgWidth?: number;
		imgHeight?: number;
		scaledX?: number;
		scaledY?: number;
	}) => void;
	setCloudProcessingConfig: (config: BaseCloudProcessingConfig) => void;
	setCalibrationResults: (results: CalibrationResponse['calibration_results']) => void;
	displayStep: ProcessingStep;
	currentStep: ProcessingStep;
	setDisplayStep: (step: ProcessingStep) => void;
	setCurrentStep: (step: ProcessingStep) => void;
	displacementProcessing: boolean;
	setDisplacementProcessing: (isDisplacement: boolean) => void;
	videoBeingProcessed: boolean;
	setVideoBeingProcessed: (isProcessing: boolean) => void;
	uploadCloudProcessingConfig: (config: BaseCloudProcessingConfig) => void;
	removeUploadedCloudProcessingConfig: (config: BaseCloudProcessingConfig) => void;
	processingTimeoutReached: boolean;
	handleProcessingTimeoutReached: (isReached: boolean) => void;
	reprocessingMeasurement: {
		reprocessing: boolean;
		originalReprocessingData: ReprocessingMeasurementsResponse | null;
	};
	toggleReprocessingMeasurement: ({
		reprocessing,
		originalReprocessingData
	}: {
		reprocessing: boolean;
		originalReprocessingData: ReprocessingMeasurementsResponse | null;
	}) => void;

	createUpdateAlarmModal: {
		open: boolean;
		alarm: SiteAlarmRetrieve | null;
	};
	toggleCreateUpdateAlarmModal: ({
		open,
		alarm
	}: {
		open: boolean;
		alarm: SiteAlarmRetrieve | null;
	}) => void;
}

export const useGlobalStore = create<GlobalState>((set) => ({
	currentOrganization: {
		id: 1,
		permissions: null,
		name: null
	},
	confirmationDialog: null,
	inviteUsersModal: {
		inviteUsersModalOpen: false,
		selectedUser: null,
		userPermissions: null
	},
	moveOrShareSiteModal: {
		moveOrShareSiteModalOpen: false,
		selectedSiteId: null
	},
	toggleMoveOrShareSiteModal: (siteId?) => {
		set((state) => ({
			moveOrShareSiteModal: {
				moveOrShareSiteModalOpen: !state.moveOrShareSiteModal.moveOrShareSiteModalOpen,
				selectedSiteId: siteId ?? null
			}
		}));
	},
	toggleInviteUsersModal: (inviteUsersInfo: {
		inviteUsersModalOpen: boolean;
		selectedUser: UserListRetrieve | null;
		userPermissions: MembershipPermissionCreateUpdate | null;
	}) => {
		set(() => ({
			inviteUsersModal: inviteUsersInfo
		}));
	},
	toggleConfirmationDialog: (confirmationDialog: {
		dialogOpen: boolean;
		headerTitle?: string;
		message: string;
		confirmAction: () => void;
		type: 'info' | 'confirmation' | null;
		confirmActionLabel?: string;
		cancelActionLabel?: string;
	}) => {
		set(() => ({
			confirmationDialog: confirmationDialog
		}));
	},
	globalSearchToggled: {
		toggled: false,
		searchType: null
	},
	organizationSwitcher: {
		toggled: false,
		organizations: null
	},
	toggleGlobalSearchBar: (globalSearchBar) => {
		set(() => ({
			globalSearchToggled: globalSearchBar
		}));
	},
	toggleOrganizationSwitchModal: (organizationSwitcher) => {
		set(() => ({
			organizationSwitcher: organizationSwitcher
		}));
	},
	selectedSiteId: null,
	setSelectedSiteId: (siteId: number | null) => {
		set(() => ({
			selectedSiteId: siteId
		}));
	},
	createStationModalOpen: false,
	toggleCreateStationModal: () => {
		set((state) => ({
			createStationModalOpen: !state.createStationModalOpen
		}));
	},
	changeSiteLocationModalOpen: false,
	toggleChangeSiteLocationModal: () => {
		set((state) => ({
			changeSiteLocationModalOpen: !state.changeSiteLocationModalOpen
		}));
	},
	siteImageModalOpen: false,
	toggleSiteImageModal: () => {
		set((state) => ({
			siteImageModalOpen: !state.siteImageModalOpen
		}));
	},
	measurementsFilterModalOpen: false,
	toggleMeasurementsFilterModal: () => {
		set((state) => ({
			measurementsFilterModalOpen: !state.measurementsFilterModalOpen
		}));
	},
	selectedMeasurementFilters: [
		{
			filterName: '',
			filterType: '',
			operator: { operator: '', value: '', label: '' },
			filterValue: ''
		}
	],
	setSelectedMeasurementFilter: (filters: MeasurementFilter[]) => {
		set(() => ({
			selectedMeasurementFilters: filters
		}));
	},
	appliedMeasurementFilters: [
		{
			filterName: '',
			filterType: '',
			operator: { operator: '', value: '', label: '' },
			filterValue: ''
		}
	],
	setAppliedMeasurementFilter: (filters: MeasurementFilter[]) => {
		set(() => ({
			appliedMeasurementFilters: filters
		}));
	},
	parsedFilters: undefined,
	setParsedFilters: (filters: ParsedFilter[]) => {
		set(() => ({
			parsedFilters: filters
		}));
	},
	selectedMeasurementId: undefined,
	setSelectedMeasurementId: (measurementId: number | undefined) => {
		set(() => ({
			selectedMeasurementId: measurementId
		}));
	},
	measurementImageVideoModal: {
		open: false,
		modalType: '',
		imageSrc: '',
		videoSrc: ''
	},
	toggleMeasurementImageVideoModal: ({ modalType, imageSrc, videoSrc, measurement }) => {
		set((state) => ({
			measurementImageVideoModal: {
				open: !state.measurementImageVideoModal.open,
				modalType: modalType,
				imageSrc: imageSrc,
				videoSrc: videoSrc,
				measurement: measurement
			}
		}));
	},
	exportMeasurementsModal: {
		open: false,
		export_timelapse: false
	},
	toggleExportMeasurementsModal: ({ open, export_timelapse }) => {
		set(() => ({
			exportMeasurementsModal: {
				open: open,
				export_timelapse: export_timelapse ?? false
			}
		}));
	},
	measurementsCalendar: {
		open: false,
		date_from: startOfDay(sub(new Date(), { days: 2 })),
		date_to: endOfDay(new Date()),
		key: ''
	},
	toggleMeasurementsCalendar: ({ open, date_from, date_to, key }) => {
		set(() => ({
			measurementsCalendar: {
				open: open,
				date_from: date_from && startOfDay(date_from),
				date_to: date_to && endOfDay(date_to),
				key: key
			}
		}));
	},
	createNewSiteModal: {
		open: false
	},
	toggleCreateNewSiteModal: () => {
		set((state) => ({
			createNewSiteModal: {
				open: !state.createNewSiteModal.open
			}
		}));
	},
	editGCPModal: { open: false, station_id: null, stationDetails: null },
	toggleEditGCPModal: ({ open, station_id, stationDetails }) => {
		set(() => ({
			editGCPModal: {
				open: open,
				station_id: station_id,
				stationDetails: stationDetails
			}
		}));
	},
	setUpCrossSectionModal: {
		open: false,
		station_id: null
	},
	toggleSetUpCrossSectionModal: ({ open, station_id }) => {
		set(() => ({
			setUpCrossSectionModal: {
				open: open,
				station_id: station_id
			}
		}));
	},
	profileCalibration: {
		profile: [],
		markers: [],
		base64Img: ''
	},
	setCalibrationImg: (img, profile, markers) => {
		set(() => ({
			profileCalibration: {
				base64Img: img,
				profile: profile,
				markers: markers
			}
		}));
	},
	freeProfileChartModal: {
		open: false,
		station_id: undefined
	},
	toggleFreeProfileChartModal: ({ open, station_id }) => {
		set(() => ({
			freeProfileChartModal: {
				open: open,
				station_id: station_id
			}
		}));
	},
	editFreeProfilePointsModal: {
		open: false,
		station_id: undefined
	},
	toggleEditFreeProfilePointsModal: ({ open, station_id }) => {
		set(() => ({
			editFreeProfilePointsModal: {
				open: open,
				station_id: station_id
			}
		}));
	},
	SIUnitsToggled: true,
	toggleSIUnits: () => {
		set((state) => ({
			SIUnitsToggled: !state.SIUnitsToggled
		}));
	},
	editCustomConfig: {
		open: false,
		station_id: null
	},
	toggleEditCustomConfig: (open, stationId) => {
		set(() => ({
			editCustomConfig: {
				open: open,
				station_id: stationId
			}
		}));
	},
	ratingCurveData: { station_id: null, site_id: null, ratingCurves: [] },
	setRatingCurveData: ({ station_id, site_id, ratingCurves }) => {
		set(() => ({
			ratingCurveData: {
				station_id: station_id,
				site_id: site_id,
				ratingCurves: ratingCurves
			}
		}));
	},
	ratingCurveFormData: {
		rating_curve: null,
		formToggled: false
	},
	setRatingCurveFormData: ({ rating_curve, formToggled }) => {
		set(() => ({
			ratingCurveFormData: {
				rating_curve: rating_curve,
				formToggled: formToggled
			}
		}));
	},
	editQHDataModal: {
		open: false
	},
	toggleEditQHDataModal: () => {
		set((state) => ({
			editQHDataModal: {
				open: !state.editQHDataModal.open
			}
		}));
	},
	userInfo: {
		timezone_display: timezone_display.LOCAL
	},
	setUserInfo: ({ timezone_display }) => {
		set(() => ({
			userInfo: {
				timezone_display: timezone_display
			}
		}));
	},
	hostname: {
		isSeba: false,
		isDefault: false
	},
	setHostname: (hostname) => {
		set(() => ({
			hostname: {
				isSeba: hostname === 'seba-discharge.de',
				isDefault: ['app.discharge.ch', 'dev.discharge.ch', 'localhost'].includes(hostname)
			}
		}));
	},
	mapClusterToggled: false,
	toggleMapCluster: () => {
		set((state) => ({
			mapClusterToggled: !state.mapClusterToggled
		}));
	},
	editAdvancedConfig: {
		open: false,
		station_id: null
	},
	toggleEditAdvancedConfig: (open, stationId) => {
		set(() => ({
			editAdvancedConfig: {
				open: open,
				station_id: stationId
			}
		}));
	},
	configHistoricalOverview: {
		open: false,
		stationId: null,
		siteId: null
	},
	toggleConfigHistoricalOverview: (open, stationId, siteId) => {
		set(() => ({
			configHistoricalOverview: {
				open: open,
				stationId: stationId,
				siteId: siteId
			}
		}));
	},
	joinOrRedirectOrganizationModal: {
		open: false,
		organizationId: null,
		orgName: '',
		error: false
	},
	toggleJoinOrRedirectOrganizationModal: (open, organizationId, orgName, error) => {
		set(() => ({
			joinOrRedirectOrganizationModal: {
				open: open,
				organizationId: organizationId,
				orgName: orgName,
				error: error
			}
		}));
	},
	//cloud processing part
	cloudProcessing: {},

	setCurrentOrganization: (org, userId) => {
		localStorage.setItem('currentOrganizationId', org.id.toString());
		localStorage.setItem(`lastOrganization-${userId}`, org.id.toString());
		// find a better place to clear the query client when a user switches to a new org
		// queryClient.clear();
		set(() => ({
			currentOrganization: org
		}));
	},

	setCloudProcessingVideo: (video) => {
		set((state) => ({
			cloudProcessing: {
				...state.cloudProcessing,
				video: video
			}
		}));
	},

	setCloudProcessingRotatedImage: ({
		imgSrc,
		rotation,
		imgOrientation,
		imgHeight,
		imgWidth,
		scaledX,
		scaledY
	}: {
		imgSrc?: string;
		rotation?: number;
		imgOrientation?: 'horizontal' | 'vertical';
		imgWidth?: number;
		imgHeight?: number;
		scaledX?: number;
		scaledY?: number;
	}) => {
		set((state) => ({
			cloudProcessing: {
				...state.cloudProcessing,
				rotatedImage: {
					imgSrc: imgSrc ?? state.cloudProcessing.rotatedImage?.imgSrc ?? '',
					rotation: rotation ?? state.cloudProcessing.rotatedImage?.rotation,
					imgOrientation: imgOrientation,
					imgHeight: imgHeight,
					imgWidth: imgWidth,
					scaledX: scaledX ?? 1,
					scaledY: scaledY ?? 1
				}
			}
		}));
	},

	setCloudProcessingConfig: (config) => {
		set((state) => ({
			cloudProcessing: {
				...state.cloudProcessing,
				config: config
			}
		}));
	},

	setCalibrationResults: (results) => {
		set((state) => ({
			cloudProcessing: {
				...state.cloudProcessing,
				calibrationResults: results
			}
		}));
	},
	displayStep: PROCESSING_STEPS[0],
	currentStep: PROCESSING_STEPS[0],
	setCurrentStep: (step) => {
		set(() => ({
			currentStep: step
		}));
	},
	setDisplayStep: (step) => {
		set(() => ({
			displayStep: step
		}));
	},
	displacementProcessing: false,
	setDisplacementProcessing: (isDisplacement) => {
		set(() => ({
			displacementProcessing: isDisplacement
		}));
	},
	videoBeingProcessed: false,
	setVideoBeingProcessed: (isProcessing) => {
		set(() => ({
			videoBeingProcessed: isProcessing
		}));
	},
	uploadCloudProcessingConfig: (config) => {
		set((state) => ({
			cloudProcessing: {
				...state.cloudProcessing,
				config: config,
				configManuallyUploaded: true
			}
		}));
	},
	removeUploadedCloudProcessingConfig: (config) => {
		set((state) => ({
			cloudProcessing: {
				...state.cloudProcessing,
				config: config,
				configManuallyUploaded: false
			}
		}));
	},
	processingTimeoutReached: false,
	handleProcessingTimeoutReached: (isReached) => {
		set(() => ({
			processingTimeoutReached: isReached
		}));
	},
	reprocessingMeasurement: { reprocessing: false, originalReprocessingData: null },
	toggleReprocessingMeasurement: ({ reprocessing, originalReprocessingData }) => {
		set(() => ({
			reprocessingMeasurement: {
				reprocessing: reprocessing,
				originalReprocessingData: originalReprocessingData
			}
		}));
	},
	createUpdateAlarmModal: { open: false, alarm: null },
	toggleCreateUpdateAlarmModal: ({ open, alarm }) => {
		set(() => ({
			createUpdateAlarmModal: {
				open: open,
				alarm: alarm
			}
		}));
	}
}));
