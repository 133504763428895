import { Delete, Edit, Help, Settings, SettingsSuggest, Visibility } from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';
import classNames from 'classnames';
import PermissionView from 'components/shared/PermissionsView/PermissionView';
import IconWithTooltip from 'components/shared/Tooltip/IconWithTooltip';
import { useFormik } from 'formik';
import { useGlobalStore } from 'global-state/useStore';
import useStationInfo from 'hooks/useStationDetails';
import { DischargeKeeperStationDetails } from 'interfaces/models/DischargeKeeperStationDetails';
import ViewMeasurementsButton from 'pages/SiteDetails/components/ViewMeasurementsButton';
import ConfigHistoricalOverviewButton from 'pages/SiteDetails/ConfigHistoricalOverviewButton';
import React, { FunctionComponent } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Autocomplete, Button, MenuItem, Popper, Select, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { queryKeys } from 'rq/constants';
import {
	useCreateStation,
	useDeleteStation,
	useStationDetails,
	useUpdateStation
} from 'rq/hooks/stationHook';
import { queryClient } from 'rq/queryClient';
import StationService from 'services/StationService/StationService';
import { profileTypes, roughnessCategories } from 'utils/helperData';
import * as Yup from 'yup';
import { IStationTabProps } from 'pages/SiteDetails/components/types';

const DischargeKeeperStationTab: FunctionComponent<IStationTabProps> = ({ station_id }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const toggleCreateStationModal = useGlobalStore((state) => state.toggleCreateStationModal);
	const selectedSiteId = useGlobalStore((state) => state.selectedSiteId);
	const toggleConfirmationDialog = useGlobalStore((state) => state.toggleConfirmationDialog);
	const currentOrganization = useGlobalStore((state) => state.currentOrganization);
	const toggleEditGCPModal = useGlobalStore((state) => state.toggleEditGCPModal);
	const toggleSetUpCrossSectionModal = useGlobalStore(
		(state) => state.toggleSetUpCrossSectionModal
	);
	const toggleFreeProfileChartModal = useGlobalStore((state) => state.toggleFreeProfileChartModal);
	const toggleEditFreeProfilePointsModal = useGlobalStore(
		(state) => state.toggleEditFreeProfilePointsModal
	);
	const toggleEditAdvancedConfig = useGlobalStore((state) => state.toggleEditAdvancedConfig);
	const toggleConfigHistoricalOverview = useGlobalStore(
		(state) => state.toggleConfigHistoricalOverview
	);

	const { mutate: createStation } = useCreateStation();
	const { mutate: updateStation } = useUpdateStation();
	const { mutate: deleteStation } = useDeleteStation();
	const { data: stationDetails, isLoading: stationLoading } =
		useStationDetails<DischargeKeeperStationDetails>(Number(selectedSiteId), station_id, {
			enabled: !!station_id
		});

	const { manageStationPermission, GCPPointsDefined, crossSectionPointsDefined } = useStationInfo(
		currentOrganization,
		stationDetails as DischargeKeeperStationDetails
	);

	const validationSchema = Yup.object().shape({
		profile: Yup.object().shape({
			type: Yup.string().required(t('REQUIRED')),
			depth: Yup.number()
				.min(0)
				.required()
				.when('profile.type', {
					is: (profileType: string) => profileType !== 'FREE',
					then: Yup.number().required(t('REQUIRED'))
				}),
			roughness: Yup.number().required().min(20),
			width: Yup.number()
				.min(0)
				.required()
				.when('profile.type', {
					is: (profileType: string) => profileType !== 'FREE',
					then: Yup.number().required(t('REQUIRED'))
				}),
			bottom_width: Yup.number()
				.min(0)
				.required()
				.when('profile.type', {
					is: (profileType: string) => profileType === 'TRAPEZOIDAL',
					then: Yup.number().required(t('REQUIRED'))
				})
		}),
		video_resolution: Yup.string().required(t('REQUIRED')) /*
		camera_position: Yup.array().of(Yup.number()).min(1, t('REQUIRED')),
		camera_rotation: Yup.array().of(Yup.number()).min(1, t('REQUIRED')),
		camera_angle: Yup.number().min(0).required(t('REQUIRED'))*/
	});

	const formik = useFormik({
		initialValues: {
			station_type: stationDetails?.station_type ?? 'DISCHARGE_KEEPER',
			image: stationDetails?.image ?? '',
			profile: stationDetails?.profile.type
				? stationDetails?.profile
				: {
						type: stationDetails?.profile.type ?? 'FREE',
						depth: stationDetails?.profile.depth ?? 0,
						roughness: stationDetails?.profile.roughness ?? 20,
						width: stationDetails?.profile.width ?? 0,
						bottom_width: stationDetails?.profile.bottom_width ?? 0,
						free_params: stationDetails?.profile.free_params ?? []
				  },
			markers_coordinates: stationDetails?.markers_coordinates ?? [],
			marker_measurements: stationDetails?.markers_measurements ?? [],
			video_resolution: stationDetails?.video_resolution ?? 720,
			camera_position:
				stationDetails?.camera_position && stationDetails?.camera_position?.length > 0
					? stationDetails?.camera_position
					: [0],
			camera_angle: stationDetails?.camera_angle ?? 0,
			camera_rotation:
				stationDetails?.camera_rotation && stationDetails?.camera_rotation?.length > 0
					? stationDetails?.camera_rotation
					: [0]
		},
		validationSchema: validationSchema,
		onSubmit: (data) => {
			if (data.profile.type === 'FREE') {
				data.profile.width = 0;
				data.profile.depth = 0;
				data.profile.bottom_width = 0;
			} else if (data.profile.type !== 'TRAPEZOIDAL') {
				data.profile.bottom_width = 0;
			}
			station_id &&
				updateStation(
					{
						siteId: Number(selectedSiteId),
						stationId: station_id,
						data: data
					},
					{
						onSuccess: () => {
							toast.success(t('STATION_UPDATED'));
							queryClient.invalidateQueries([
								queryKeys.station_details,
								`site-id-${selectedSiteId}`
							]);
						}
					}
				);
			!station_id &&
				createStation(
					{
						siteId: Number(selectedSiteId),
						data: data
					},
					{
						onSuccess: () => {
							toast.success(t('STATION_UPDATED'));
							toggleCreateStationModal();
							queryClient.invalidateQueries([queryKeys.stations, `site-${selectedSiteId}`]);
						}
					}
				);
		},
		enableReinitialize: true
	});

	if (stationLoading)
		return (
			<div className={'app flex h-full flex-col items-center justify-center text-secondary'}>
				<CircularProgress size={50} thickness={4} />
			</div>
		);

	const handleStationDelete = () => {
		deleteStation(
			{ site_id: Number(selectedSiteId), station_id: Number(station_id) },
			{
				onSuccess: () => {
					toast.success(t('STATION_DELETED'));
					queryClient.invalidateQueries([queryKeys.stations, `site-${selectedSiteId}`]);
				}
			}
		);
	};
	const handleViewMeasurementsClick = () => {
		navigate(`/${currentOrganization.id}/measurements/`, {
			state: {
				siteId: selectedSiteId,
				stationId: station_id
			}
		});
	};

	const handleConfigOverviewClick = () => {
		toggleConfigHistoricalOverview(true, Number(station_id), Number(selectedSiteId));
	};

	return (
		<div>
			<div className={'flex w-full flex-row flex-wrap justify-between gap-2'}>
				<div className={'flex flex-wrap gap-2'}>
					{' '}
					{station_id && <ViewMeasurementsButton handleClick={handleViewMeasurementsClick} />}
					{station_id && (
						<ConfigHistoricalOverviewButton
							handleClick={handleConfigOverviewClick}></ConfigHistoricalOverviewButton>
					)}
				</div>
				{station_id && (
					<div className={'flex items-center gap-1 align-middle'}>
						<div className={'flex w-full justify-end text-xs'}>
							{t('ID')}: {station_id}
						</div>
						<IconWithTooltip
							title={`${t('DELETE')} ${t('STATION').toLowerCase()}`}
							icon={
								<Delete
									className={'text-danger'}
									onClick={() => {
										toggleConfirmationDialog({
											confirmAction: handleStationDelete,
											dialogOpen: true,
											type: 'confirmation',
											message: t('DELETE_PROMPT') + '?'
										});
									}}
								/>
							}
						/>
					</div>
				)}
			</div>
			<form onSubmit={formik.handleSubmit} autoComplete={'off'}>
				<div className={'mt-4 flex flex-col gap-10 md:gap-5'}>
					<div className={'flex w-full flex-col lg:flex-row'}>
						<div
							className={'flex items-center align-middle max-md:text-lg max-md:font-bold md:w-60'}>
							{t('PROFILE')}
						</div>
						<div className={'flex w-full gap-4 normal-case max-md:flex-col'}>
							<Select
								className={'h-10 w-full max-w-[15rem]'}
								name={'profile.type'}
								value={formik.values.profile?.type ?? 'FREE'}
								onChange={formik.handleChange}
								disabled={!manageStationPermission}
								onBlur={formik.handleBlur}
								error={formik.touched.profile?.type && Boolean(formik.errors.profile?.type)}>
								{profileTypes.map((profileType) => (
									<MenuItem value={profileType} key={profileType}>
										<span>
											{profileType.toLowerCase().charAt(0).toUpperCase() +
												profileType.toLowerCase().slice(1)}
										</span>
									</MenuItem>
								))}
							</Select>
							<div
								className={
									'flex items-center gap-3 rounded-lg border border-gray-400 bg-neutral-200 px-4 align-middle text-xs max-md:mt-2 max-md:py-2 max-sm:w-[15rem] max-sm:flex-col md:h-full'
								}>
								{formik.values.profile.type !== 'FREE' ? (
									<>
										<span>{t('WIDTH_M')}</span>
										<TextField
											variant={'standard'}
											className={'h-8 text-sm md:max-w-[5rem]'}
											type={'number'}
											required={true}
											name={'profile.width'}
											value={formik.values.profile?.width}
											InputProps={{
												inputProps: { min: 0 },
												style: { fontSize: 'medium' }
											}}
											onChange={formik.handleChange}
											disabled={!manageStationPermission}
											onBlur={formik.handleBlur}
											error={formik.touched.profile?.width && Boolean(formik.errors.profile?.width)}
										/>
										<div>{t('DEPTH_M')}</div>
										<TextField
											variant={'standard'}
											className={'h-8 md:max-w-[5rem]'}
											type={'number'}
											name={'profile.depth'}
											value={formik.values.profile?.depth}
											InputProps={{
												inputProps: { min: 0 }
											}}
											onChange={formik.handleChange}
											disabled={!manageStationPermission}
											onBlur={formik.handleBlur}
											error={formik.touched.profile?.depth && Boolean(formik.errors.profile?.depth)}
										/>
										{formik.values.profile.type === 'TRAPEZOIDAL' && (
											<>
												{' '}
												<span>{t('BOTTOM_WIDTH_M')}</span>
												<TextField
													variant={'standard'}
													className={'h-8 w-auto md:max-w-[5rem]'}
													type={'number'}
													name={'profile.bottom_width'}
													value={formik.values.profile?.bottom_width}
													InputProps={{
														inputProps: { min: 0 }
													}}
													onChange={formik.handleChange}
													disabled={!manageStationPermission}
													onBlur={formik.handleBlur}
													error={
														formik.touched.profile?.bottom_width &&
														Boolean(formik.errors.profile?.bottom_width)
													}
												/>
											</>
										)}
									</>
								) : (
									<>
										<div className={'flex w-full items-center gap-3 align-middle'}>
											<span>
												{crossSectionPointsDefined
													? t('POINTS_CROSS_SECTION_DEFINED', { value: crossSectionPointsDefined })
													: t('NO_POINTS_DEFINED')}
											</span>
											<div className={'flex h-full flex-row flex-wrap gap-6 md:p-2'}>
												<div
													className={classNames(
														{
															'flex items-center gap-2 align-middle ': true
														},
														{ 'cursor-pointer hover:opacity-50': !!stationDetails }
													)}
													onClick={() =>
														stationDetails &&
														toggleFreeProfileChartModal({
															open: true,
															station_id: stationDetails?.station_id
														})
													}>
													<Visibility className={'text-gray-500'} /> {t('PREVIEW')}
												</div>
												<PermissionView
													requiredPermissions={['manage_sites']}
													showFallbackComponent={false}>
													<>
														<div
															className={classNames(
																{
																	'flex items-center gap-2 align-middle': true
																},
																{ 'cursor-pointer hover:opacity-50': !!stationDetails }
															)}
															onClick={() => {
																stationDetails &&
																	toggleSetUpCrossSectionModal({
																		open: true,
																		station_id: stationDetails.station_id
																	});
															}}>
															<Settings className={'text-gray-500'} /> {t('CONFIGURE')}
														</div>

														<div
															className={classNames(
																{
																	' flex items-center gap-2 align-middle': true
																},
																{ 'cursor-pointer hover:opacity-50': !!stationDetails }
															)}
															onClick={() => {
																stationDetails &&
																	toggleEditFreeProfilePointsModal({
																		open: true,
																		station_id: stationDetails.station_id
																	});
															}}>
															<Edit className={'text-gray-500'} /> {t('EDIT')}
														</div>
													</>
												</PermissionView>
											</div>
										</div>
									</>
								)}
							</div>
						</div>
					</div>
					<div className={'flex h-10 w-full max-md:flex-col'}>
						<div className={'flex items-center max-md:text-lg max-md:font-bold md:w-60'}>
							{t('GCP')}
						</div>
						<div className={'flex w-full max-md:flex-col'}>
							<div className={'mr-4 flex h-full w-full max-w-[10rem] items-center align-middle'}>
								{GCPPointsDefined
									? `${GCPPointsDefined} ${t('POINTS_DEFINED')}`
									: t('NO_POINTS_DEFINED')}
							</div>
							<PermissionView requiredPermissions={['manage_sites']} showFallbackComponent={false}>
								<div className={'flex h-full flex-row flex-wrap gap-2 md:p-2'}>
									{/*	<div className={'mr-4 flex cursor-pointer items-center gap-2 align-middle'}>
									<Visibility className={'text-gray-500'} /> {t('PREVIEW')}
								</div>*/}
									<div
										className={'flex cursor-pointer items-center gap-2 align-middle'}
										onClick={() => {
											stationDetails &&
												toggleEditGCPModal({
													open: true,
													station_id: stationDetails.station_id,
													stationDetails: stationDetails
												});
										}}>
										<Settings className={'text-gray-500'} /> {t('CONFIGURE')}
									</div>
								</div>
							</PermissionView>
						</div>
					</div>
					<div className={'flex w-full max-md:flex-col md:h-full'}>
						<div className={'flex items-center gap-2 max-md:text-lg max-md:font-bold md:w-60'}>
							{t('ROUGHNESS')}
						</div>
						<div className={'flex w-full  items-center gap-2 '}>
							<Autocomplete
								freeSolo={true}
								options={roughnessCategories}
								groupBy={(option) => option.group}
								getOptionLabel={(option) =>
									option.value
										? option.value.toString()
										: formik.values.profile.roughness.toString()
								}
								renderOption={(props, option) => {
									return (
										<span {...props} key={`${option.name} - ${option.value}`}>
											{t(option.name) + ` (${option.displayValue})`}
										</span>
									);
								}}
								value={
									roughnessCategories.find(
										(option) => option.value === formik.values.profile.roughness
									) ?? formik.values.profile.roughness?.toString()
								}
								className={'h-10 w-full max-w-[15rem]'}
								onBlur={formik.handleBlur}
								onChange={(e, newValue: any) => {
									formik.setFieldValue(
										'profile.roughness',
										!isNaN(Number(newValue?.value)) ? Number(newValue?.value) : Number(newValue)
									);
								}}
								disabled={!manageStationPermission}
								renderInput={(params) => (
									<TextField
										{...params}
										name={'profile.roughness'}
										className={'w-full max-w-[15rem]'}
										size={'small'}
										onChange={(e) =>
											formik.setFieldValue('profile.roughness', Number(e.target.value))
										}
										disabled={!manageStationPermission}
										value={formik.values.profile?.roughness.toString()}
									/>
								)}
								renderGroup={(params) => (
									<li key={params.key} className={'flex flex-col gap-2'}>
										<div className={'px-2 font-bold'}>{t(params.group)}</div>
										<div>{params.children}</div>
									</li>
								)}
								PopperComponent={(props) => (
									<Popper {...props} style={{ width: 'fit-content' }} placement={'bottom-start'} />
								)}
							/>
							<Help className={'text-gray-500'}></Help>
						</div>
					</div>
					<div className={'flex w-full max-md:flex-col'}>
						<div className={'flex items-center gap-2 max-md:text-lg max-md:font-bold md:w-60'}>
							{t('VIDEO_RESOLUTION')}
						</div>
						<div className={'flex w-full flex-row items-center gap-2 align-middle'}>
							<Select
								className={'h-10 w-full max-w-[10rem]'}
								name={'video_resolution'}
								value={formik.values.video_resolution}
								onChange={formik.handleChange}
								disabled={!manageStationPermission}
								error={formik.touched.video_resolution && Boolean(formik.errors.video_resolution)}>
								<MenuItem value={'720'}>{'720p (default)'}</MenuItem>
								<MenuItem value={'1080'}>{'1080p'}</MenuItem>
							</Select>{' '}
							<Help className={'text-gray-500'}></Help>
						</div>
					</div>

					<div className={'flex w-full flex-col lg:flex-row'}>
						<div
							className={'flex items-center align-middle max-md:text-lg max-md:font-bold md:w-60'}>
							{t('CAMERA')}
						</div>
						<div className={'flex w-full gap-4 normal-case max-md:flex-col'}>
							<div
								className={
									'flex gap-3 max-md:mt-2 max-md:flex-col md:h-full md:items-center md:align-middle'
								}>
								<span>{t('POSITION')}</span>
								<TextField
									variant={'standard'}
									className={'h-10 max-w-[15rem]'}
									name={'camera_position'}
									value={formik.values.camera_position}
									onChange={(e) => {
										if (e.target.value !== '') {
											const arrayOfValues = e.target.value.replace(/ /g, '').split(',');
											formik.setFieldValue('camera_position', arrayOfValues);
										} else formik.setFieldValue('camera_position', ['0']);
									}}
									disabled={!manageStationPermission}
									onBlur={formik.handleBlur}
									error={formik.touched.camera_position && Boolean(formik.errors.camera_position)}
								/>
								<span>{t('ROTATION')}</span>
								<TextField
									variant={'standard'}
									className={'h-10 max-w-[15rem]'}
									name={'camera_rotation'}
									value={formik.values.camera_rotation}
									onChange={(e) => {
										if (e.target.value !== '') {
											const arrayOfValues = e.target.value.replace(/ /g, '').split(',');
											formik.setFieldValue('camera_rotation', arrayOfValues);
										} else formik.setFieldValue('camera_rotation', ['0']);
									}}
									disabled={!manageStationPermission}
									onBlur={formik.handleBlur}
									error={formik.touched.camera_rotation && Boolean(formik.errors.camera_rotation)}
								/>
								<span>{t('ANGLE')}</span>
								<TextField
									variant={'standard'}
									className={'h-10 max-w-[15rem]'}
									type={'number'}
									name={'camera_angle'}
									value={formik.values.camera_angle}
									onChange={(e) => {
										if (e.target.value !== '') {
											formik.setFieldValue('camera_rotation', e.target.value);
										} else formik.setFieldValue('camera_angle', '0');
									}}
									disabled={!manageStationPermission}
									onBlur={formik.handleBlur}
									InputProps={{
										inputProps: { min: 0 }
									}}
									error={formik.touched.camera_angle && Boolean(formik.errors.camera_angle)}
								/>
							</div>
						</div>
					</div>
					<PermissionView requiredPermissions={['manage_sites']} showFallbackComponent={false}>
						<>
							{/*Discharge keeper won't have custom configuration so it is hidden*/}
							{/*		<div className={'flex h-10 w-full items-center align-middle max-md:flex-col'}>
								<div className={'flex items-center max-md:text-lg max-md:font-bold md:w-60'}>
									{t('CUSTOM_CONFIGURATION')}
								</div>
								<div className={'w-full'}>
									<div className={'flex cursor-pointer items-center gap-2 align-middle'}>
										<Settings
											className={'text-gray-500'}
											onClick={() => toggleEditCustomConfig(true, Number(station_id))}
										/>
										<span className={'cursor-auto'}>{t('CONFIGURE')}</span>{' '}
										<Help className={'text-gray-500'}></Help>
									</div>
								</div>
							</div>*/}

							<div className={'flex h-10 w-full items-center align-middle max-md:flex-col'}>
								<div className={'flex items-center max-md:text-lg max-md:font-bold md:w-60'}>
									{t('ADVANCED_CONFIGURATION')}
								</div>
								<div className={'w-full'}>
									<div className={'flex cursor-pointer items-center gap-2 align-middle'}>
										<SettingsSuggest
											className={'text-gray-500'}
											onClick={() => toggleEditAdvancedConfig(true, Number(station_id))}
										/>
										<span className={'cursor-auto'}>{t('CONFIGURE')}</span>{' '}
										<Help className={'text-gray-500'}></Help>
									</div>
								</div>
							</div>
							<div></div>
						</>
					</PermissionView>
				</div>

				<div className={'mt-4 flex justify-center gap-4'}>
					<Button
						type={'submit'}
						disabled={!formik.dirty || !formik.isValid || !manageStationPermission}
						variant={'contained'}
						className={'bg-secondary normal-case text-primary disabled:bg-gray-500'}>
						{station_id ? t('UPDATE') : t('CREATE')}
					</Button>

					{station_id && stationDetails?.deployment_status !== 'NONE' && (
						<Button
							disabled={stationDetails?.deployment_status !== 'DRAFT'}
							variant={'contained'}
							onClick={() =>
								StationService.deployDischargeKeeperConfig({
									site_id: Number(selectedSiteId),
									station_id: Number(station_id)
								}).then(() => {
									toast.success('Success');
									queryClient.invalidateQueries([
										queryKeys.station_details,
										`site-id-${selectedSiteId}`,
										`station-id-${station_id}`
									]);
								})
							}
							className={'bg-accent normal-case text-primary disabled:bg-gray-500'}>
							{stationDetails?.deployment_status === 'DRAFT'
								? t('DEPLOY')
								: t(stationDetails?.deployment_status ?? '')}
						</Button>
					)}
				</div>
			</form>
		</div>
	);
};
export default DischargeKeeperStationTab;
