import { CloudSync } from '@mui/icons-material';
import { LinearProgress } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import PrimaryButton from 'components/shared/Button/PrimaryButton';
import PermissionView from 'components/shared/PermissionsView/PermissionView';
import { useGlobalStore } from 'global-state/useStore';
import { ISitesPageProps } from 'pages/Sites/types';
import React, { FunctionComponent, useEffect, useState } from 'react';

import WavesIcon from '@mui/icons-material/Waves';
import SearchIcon from '@mui/icons-material/Search';
import CallMadeIcon from '@mui/icons-material/CallMade';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddLocationIcon from '@mui/icons-material/AddLocation';

import TextField from '@mui/material/TextField';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { GridColDef } from '@mui/x-data-grid';
import Avatar from '@mui/material/Avatar';

import IconWithTooltip from 'components/shared/Tooltip/IconWithTooltip';
import { useNavigate } from 'react-router-dom';
import { queryKeys } from 'rq/constants';
import { useOrganizationDetails } from 'rq/hooks/organizationsHook';
import { useRemoveAssociation, useSitesPaginated } from 'rq/hooks/sitesHook';
import useDebounce from 'rq/hooks/useDebounce';
import { queryClient } from 'rq/queryClient';
import { StyledDataGrid } from 'components/shared/DataGrid/StyledDataGrid';

const SitesPage: FunctionComponent<ISitesPageProps> = ({ isCloudProcessing }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { mutate: removeAssociation } = useRemoveAssociation();

	const toggleMoveOrShareSiteModal = useGlobalStore((state) => state.toggleMoveOrShareSiteModal);
	const toggleConfirmationDialog = useGlobalStore((state) => state.toggleConfirmationDialog);
	const currentOrganization = useGlobalStore((state) => state.currentOrganization);
	const setSelectedSiteId = useGlobalStore((state) => state.setSelectedSiteId);
	const toggleCreateNewSiteModal = useGlobalStore((state) => state.toggleCreateNewSiteModal);

	const removeSite = (siteId: number) => {
		currentOrganization.id &&
			removeAssociation(
				{
					site_id: siteId as number,
					organization_id: currentOrganization.id
				},
				{
					onSuccess: () => {
						queryClient.invalidateQueries(queryKeys.sites_paginated);
						toast.success(t('SITE_REMOVED'));
					}
				}
			);
	};

	const { data: currentOrg } = useOrganizationDetails(
		localStorage.getItem('currentOrganizationId') as string
	);

	const [filter, setFilter] = useState('');

	const [paginationModel, setPaginationModel] = useState({
		pageSize: 10,
		page: 0
	});

	const debouncedSearchQuery = useDebounce(filter);

	const {
		data: sites,
		isLoading: sitesLoading,
		isFetching: sitesFetching
	} = useSitesPaginated(
		{
			queryParams: {
				search: debouncedSearchQuery,
				page: paginationModel.page + 1,
				page_size: paginationModel.pageSize
			}
		},
		{ keepPreviousData: true }
	);
	const filterHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		setFilter(event.target.value);
	};

	useEffect(() => {
		if (filter === '') {
			setPaginationModel({ ...paginationModel, page: 0 });
		}
	}, [filter]);

	const columns: GridColDef[] = [
		{
			field: 'site_id'
			/*		hide: true*/
		},
		{
			field: 'image',
			headerName: `${t('IMAGE')}`,
			flex: 0.5,
			minWidth: 70,
			width: 70,
			filterable: false,
			renderCell: ({ value, row }) => {
				return (
					<Avatar
						src={value}
						onClick={() => {
							setSelectedSiteId(row.site_id);
							navigate(`/${currentOrganization.id}/sites/${row.site_id}`);
						}}
						className={'m-auto bg-secondary'}>
						<WavesIcon />
					</Avatar>
				);
			},
			renderHeader: () => null,
			hideSortIcons: true,
			sortable: false
		},
		{
			field: 'name',
			headerName: `${t('NAME')}`,
			minWidth: 100,
			flex: 1.5,
			renderCell: (params) => {
				return (
					<span
						onClick={() => {
							setSelectedSiteId(params.row.site_id);
							navigate(`/${currentOrganization.id}/sites/${params.row.site_id}`);
						}}>
						{t(`${params.row.name}`)}
					</span>
				);
			}
		},
		{
			field: 'timezone',
			headerName: `${t('TIMEZONE_DISPLAY')}`,
			minWidth: 100,
			flex: 1.5
		},
		{
			field: 'profile',
			headerName: `${t('PROFILE')}`,
			flex: 1,
			renderCell: (params) => {
				return <>{t(`${params.row.profile.type.toUpperCase()}`)}</>;
			},
			minWidth: 100
		},

		{
			field: 'actions',
			headerName: `${t('ACTION')}`,
			filterable: false,
			minWidth: isCloudProcessing ? 300 : 150,
			disableColumnMenu: true,
			disableExport: true,
			sortable: false,
			hideable: false,
			editable: false,
			renderCell: (data) => {
				return (
					<div className={'flex flex-wrap'} onClick={(e) => e.stopPropagation()}>
						{!isCloudProcessing ? (
							<PermissionView requiredPermissions={['manage_sites']} showFallbackComponent={false}>
								<>
									<div
										className={'mr-2 mb-2'}
										onClick={() => toggleMoveOrShareSiteModal(data.row.site_id)}>
										<IconWithTooltip
											title={`${t('MOVE_OR_SHARE_SITE')}`}
											icon={<CallMadeIcon className={'text-secondary'} />}
										/>
									</div>

									<div
										className={'mr-2 mb-2'}
										onClick={() =>
											toggleConfirmationDialog({
												dialogOpen: true,
												message: t('REMOVE_SITE_FROM_ORGANIZATION'),
												confirmAction: () => removeSite(data.row.site_id),
												confirmActionLabel: t('REMOVE'),
												cancelActionLabel: t('CANCEL'),
												type: 'confirmation'
											})
										}>
										<IconWithTooltip
											title={`${t('REMOVE_SITE_FROM_ORGANIZATION')}`}
											icon={<DeleteOutlineIcon className={'text-accent'} />}
										/>
									</div>
								</>
							</PermissionView>
						) : (
							<PermissionView
								requiredPermissions={['create_measurements']}
								showFallbackComponent={false}>
								<>
									<div
										className={'mr-2 mb-2'}
										onClick={() =>
											navigate(`/${currentOrganization.id}/cloudprocessing/${data.row.site_id}`)
										}>
										<PrimaryButton className={'flex gap-2 bg-secondary'}>
											<CloudSync className={'text-white'} />
											<span className={'text-white'}>{t('START_CLOUD_PROCESSING')}</span>
										</PrimaryButton>
									</div>
								</>
							</PermissionView>
						)}
					</div>
				);
			}
		}
	];

	if (sitesLoading) {
		return (
			<div className={'app flex h-full flex-col items-center justify-center text-secondary'}>
				<CircularProgress size={75} thickness={5} />
			</div>
		);
	}

	//TODO kao props proslijedit isCloudProcessing tako da se zna da se došlo sa cloud processing pagea i onda prominit akcije koje se prikazuju

	return (
		<div className={'flex w-full flex-col items-center justify-items-center'}>
			<>
				<div className="mb-4 flex flex-row items-center justify-start">
					<SearchIcon className="mt-4 mr-4" />
					<TextField
						id="standard-basic"
						label={t('FIND_SITE') + '...'}
						variant={'standard'}
						value={filter}
						onChange={filterHandler}
					/>
				</div>

				<div className={'mb-4 flex w-full flex-col gap-2'}>
					<div className={' flex flex-wrap justify-between'}>
						<div className={'text-xl'}>
							{currentOrg?.name}{' '}
							{isCloudProcessing ? t('CLOUD_PROCESSING').toLowerCase() : t('SITES')}
						</div>
						{!isCloudProcessing && (
							<PermissionView requiredPermissions={['manage_sites']} showFallbackComponent={false}>
								<PrimaryButton
									onClick={toggleCreateNewSiteModal}
									className={'flex gap-2 bg-secondary'}>
									<AddLocationIcon className={'text-white'} />
									<span className={'text-white'}>{t('NEW_SITE')}</span>
								</PrimaryButton>
							</PermissionView>
						)}
					</div>

					<StyledDataGrid
						columns={columns}
						rows={sites?.results ?? []}
						rowHeight={60}
						className={'shadow-xl'}
						pagination
						paginationModel={paginationModel}
						onPaginationModelChange={setPaginationModel}
						initialState={{
							columns: {
								columnVisibilityModel: {
									site_id: false
								}
							},
							pagination: {
								paginationModel: paginationModel
							}
						}}
						getRowId={(row) => row.site_id}
						pageSizeOptions={[5, 10, 15, 20]}
						rowCount={sites?.count}
						paginationMode="server"
						loading={sitesFetching}
						disableColumnMenu
						hideFooterSelectedRowCount
						slots={{ loadingOverlay: LinearProgress }}
						autoHeight
						onRowClick={(data) => {
							setSelectedSiteId(data.row.site_id);
							navigate(`/${currentOrganization.id}/sites/${data.row.site_id}`);
						}}
					/>
				</div>
			</>
		</div>
	);
};
export default SitesPage;
