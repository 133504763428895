import { useGlobalStore } from 'global-state/useStore';
import { getImageOrientation, isoDateWithoutTimeZone } from 'helper/helperFunctions';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Button, CircularProgress, TextField } from '@mui/material';
import {
	useBaseCloudProcessingConfig,
	useProcessVideo,
	useRotateImg,
	useUploadVideo
} from 'rq/hooks/cloudProcessingHook';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { PROCESSING_STEPS } from 'pages/CloudProcessing/ProcessingSteps';
import { toast } from 'react-hot-toast';
import ImageRotation from '../ImageRotation/ImageRotation';
import { Delete } from '@mui/icons-material';
import { useFormik } from 'formik';
import { BaseCloudProcessingConfig } from 'services/CloudProcessingService/types';
import * as Yup from 'yup';
import Processing from '../Processing/Processing';
import Dropzone from 'react-dropzone';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

const UploadVideo = () => {
	const { t } = useTranslation();
	const { siteId } = useParams();

	const setCurrentStep = useGlobalStore((state) => state.setCurrentStep);
	const currentStep = useGlobalStore((state) => state.currentStep);
	const setCloudProcessingVideo = useGlobalStore((state) => state.setCloudProcessingVideo);
	const setCloudProcessingConfig = useGlobalStore((state) => state.setCloudProcessingConfig);
	const uploadCloudProcessingConfig = useGlobalStore((state) => state.uploadCloudProcessingConfig);
	const setVideoBeingProcessed = useGlobalStore((state) => state.setVideoBeingProcessed);
	const removeUploadedCloudProcessingConfig = useGlobalStore(
		(state) => state.removeUploadedCloudProcessingConfig
	);
	const cloudProcessing = useGlobalStore((state) => state.cloudProcessing);
	const videoBeingProcessed = useGlobalStore((state) => state.videoBeingProcessed);
	const setCloudProcessingRotatedImage = useGlobalStore(
		(state) => state.setCloudProcessingRotatedImage
	);
	const displacementProcessing = useGlobalStore((state) => state.displacementProcessing);
	const processingTimeoutReached = useGlobalStore((state) => state.processingTimeoutReached);
	const handleProcessingTimeoutReached = useGlobalStore(
		(state) => state.handleProcessingTimeoutReached
	);
	const reprocessingMeasurement = useGlobalStore((state) => state.reprocessingMeasurement);

	const configInputRef = useRef<HTMLInputElement | null>(null);
	const [video, setVideo] = useState<File>();
	const [videoUploaded, setVideoUploaded] = useState<boolean>(false);

	const { mutate: uploadVideoRequest, isLoading: uploadingVideo } = useUploadVideo();
	const { mutate: processVideo } = useProcessVideo();
	const { mutate: rotateImageRequest } = useRotateImg();

	const { data: config } = useBaseCloudProcessingConfig({
		onSuccess: (data) => {
			setCloudProcessingConfig(data);
		},
		onError: (err: any) => {
			toast.error(t(err.message));
		},
		enabled: !cloudProcessing.configManuallyUploaded
	});

	useEffect(() => {
		if (processingTimeoutReached) handleProcessingTimeoutReached(false);
		removeUploadedCloudProcessingConfig(config);
		formik.resetForm();
		!cloudProcessing.configManuallyUploaded && setCloudProcessingConfig(config);
	}, []);

	const validationSchema = Yup.object().shape({
		created: Yup.string().required(),

		w_col: Yup.number().required().min(0)
	});

	const formik = useFormik({
		initialValues: {
			created: '',
			w_col: 0,
			config: cloudProcessing.config as BaseCloudProcessingConfig
		},
		validationSchema: validationSchema,
		onSubmit: (data) => {
			videoUploaded &&
				cloudProcessing.configManuallyUploaded &&
				processVideo(
					{
						body: {
							parameters: {
								config: data.config,
								created: isoDateWithoutTimeZone(data.created) ?? '',
								utc_offset: '+00:00',
								w_col: data.w_col,
								use_stiv_processing: cloudProcessing.config
									? cloudProcessing.config[1].parameters.velocity.stiv
									: false,
								is_direct_processing: cloudProcessing.configManuallyUploaded
							}
						},
						processing_id: `${cloudProcessing.video?.processing_id}`
					},
					{
						onError: (err: any) => {
							toast.error(err.message);
						}
					}
				);
		}
	});

	const videoSelectHandler = (file: any) => {
		setVideo(file);
		setCurrentStep(PROCESSING_STEPS[0]);
		setVideoUploaded(false);
	};

	const configSelectHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!event.target.files || event.target.files?.length === 0) return;
		const file = event.target.files[0];
		const reader = new FileReader();
		reader.readAsText(file);
		reader.onload = () => {
			const validJson = () => {
				try {
					JSON.parse(reader.result as string);
				} catch (e) {
					return false;
				}
				return true;
			};

			if (validJson()) uploadCloudProcessingConfig(JSON.parse(reader.result as string));
		};

		if (configInputRef.current) {
			configInputRef.current.value === '';
		}
	};
	const uploadHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		event.preventDefault();
		event.stopPropagation();
		setVideoUploaded(false);
		if (!video) return;
		!videoUploaded &&
			uploadVideoRequest(
				{
					siteId: Number(siteId),
					video: video
				},
				{
					onSuccess: (data) => {
						setVideoUploaded(true);
						setCloudProcessingVideo(data);
						if (processingTimeoutReached) handleProcessingTimeoutReached(false);

						rotateImageRequest(
							{
								processing_id: data.processing_id,
								body: {
									rotation_value: cloudProcessing.config && cloudProcessing.config[1].rotate
								}
							},
							{
								onSuccess: (data) => {
									const image = new Image();
									image.src = data.image;
									setCloudProcessingRotatedImage({
										imgSrc: data.image,
										rotation: cloudProcessing.config && cloudProcessing.config[1].rotate,
										imgOrientation: getImageOrientation(image)
									});
								},
								onError: (err: any) => {
									toast.error(err.message);
								}
							}
						);
						if (cloudProcessing.configManuallyUploaded) {
							setVideoBeingProcessed(true);
							if (processingTimeoutReached) handleProcessingTimeoutReached(false);
							processVideo({
								body: {
									parameters: {
										config: cloudProcessing.config,
										created: isoDateWithoutTimeZone(formik.values.created) ?? '',
										utc_offset: '+00:00',
										w_col: formik.values.w_col,
										use_stiv_processing:
											cloudProcessing.config && cloudProcessing.config[1].parameters.velocity.stiv
												? cloudProcessing.config[1].parameters.velocity.stiv
												: false,
										is_direct_processing: cloudProcessing.configManuallyUploaded
									}
								},
								processing_id: data.processing_id
							});
						}
					},
					onError: (err: any) => {
						toast.error(t(err.details));
					}
				}
			);

		if (cloudProcessing.configManuallyUploaded && videoUploaded) {
			cloudProcessing.video &&
				rotateImageRequest(
					{
						processing_id: cloudProcessing.video.processing_id,
						body: {
							rotation_value: cloudProcessing.config[1].rotate
						}
					},
					{
						onSuccess: (data) => {
							const image = new Image();
							image.src = data.image;
							setCloudProcessingRotatedImage({
								imgSrc: data.image,
								rotation: cloudProcessing.config && cloudProcessing.config[1].rotate,
								imgOrientation: getImageOrientation(image)
							});
						},
						onError: (err: any) => {
							toast.error(err.message);
						}
					}
				);
			setVideoBeingProcessed(true);
			if (processingTimeoutReached) handleProcessingTimeoutReached(false);
			processVideo({
				body: {
					parameters: {
						config: cloudProcessing.config,
						created: isoDateWithoutTimeZone(formik.values.created) ?? '',
						utc_offset: '+00:00',
						w_col: formik.values.w_col,
						use_stiv_processing:
							cloudProcessing.config && cloudProcessing.config[1].parameters.velocity.stiv
								? cloudProcessing.config[1].parameters.velocity.stiv
								: false,
						is_direct_processing: cloudProcessing.configManuallyUploaded
					}
				},
				processing_id: cloudProcessing.video?.processing_id ?? ''
			});
		}
	};

	return (
		<div className=" text-lg text-secondary">
			<div className="inline-flex flex-col">
				{currentStep.name === 'UPLOAD' && !reprocessingMeasurement.reprocessing && (
					<div className="mb-4">{t('UPLOAD_VIDEO')}</div>
				)}
				<div className="flex min-h-[20rem] min-w-[30rem] flex-col ">
					<div className={'flex w-full flex-col items-center justify-center'}>
						{!reprocessingMeasurement.reprocessing && (
							<div className="flex w-full flex-col items-center justify-center px-4">
								<Dropzone
									onDrop={(acceptedFiles) => videoSelectHandler(acceptedFiles[0])}
									maxFiles={1}
									accept={{ 'video/mp4': ['.mp4', '.MP4'] }}>
									{({ getRootProps, getInputProps }) => (
										<section
											className={
												'min-w-96 h-28 w-full flex-col items-center justify-center border-2 border-dashed'
											}>
											{!video && (
												<>
													<div
														{...getRootProps()}
														className={'flex h-full w-full flex-col items-center justify-center'}>
														<input {...getInputProps()} />
														<CloudUploadIcon
															fontSize="inherit"
															className="text-[3rem] text-secondary"
														/>
														<div>
															<div className="text-black">{t('CHOOSE_VIDEO')}</div>
														</div>
													</div>
												</>
											)}
											{video && (
												<>
													<div className="my-auto flex h-full items-center justify-center text-lg font-bold text-secondary">
														{video.name}
														<Delete
															onClick={() => {
																setVideo(undefined);
																removeUploadedCloudProcessingConfig(config);
																formik.resetForm();
															}}
															className={'ml-2 cursor-pointer text-red-500'}></Delete>
													</div>
												</>
											)}
										</section>
									)}
								</Dropzone>
							</div>
						)}
						<div className={' pt-4 text-black'}>
							{!cloudProcessing.configManuallyUploaded &&
							!displacementProcessing &&
							!uploadingVideo &&
							!videoUploaded &&
							video ? (
								<span>
									<input
										type="file"
										className="hidden"
										ref={configInputRef}
										onChange={configSelectHandler}
									/>
									<a
										href={''}
										className={'text-blue-500 underline'}
										onClick={(e) => {
											e.preventDefault();
											configInputRef.current?.click();
										}}>
										{t('ADD_CONFIGURATION')}
									</a>
									{' ' + t('TO_SKIP_MANUAL_STEPS').toLowerCase()}
								</span>
							) : (
								!videoBeingProcessed &&
								!uploadingVideo &&
								cloudProcessing.configManuallyUploaded && (
									<>
										<div>
											<span className={'flex items-center justify-center'}>
												{t('CONFIGURATION_LOADED')}{' '}
												<Delete
													className={'ml-2 cursor-pointer text-red-500'}
													onClick={() => {
														removeUploadedCloudProcessingConfig(config);
														formik.resetForm();
													}}
												/>
											</span>
											<div className="mt-6 flex flex-row">
												<div className={'flex flex-col'}>
													<div>{t('CREATED_AT_UTC')}:</div>
													<div className={'flex flex-row'}>
														<DateTimePicker
															disableFuture
															ampm={false}
															value={new Date(formik.values.created)}
															onChange={(value: Date | null) =>
																formik.setFieldValue('created', value)
															}
															slotProps={{ textField: { size: 'small' } }}
														/>
													</div>
												</div>
											</div>
											<div>{t('WATER_LEVEL')}</div>
											<TextField
												size={'small'}
												className="mb-4 mr-1 inline-block w-24"
												name={'w_col'}
												value={formik.values.w_col}
												onChange={formik.handleChange}
											/>
										</div>
										<div className={'flex justify-center'}>
											{videoUploaded && !videoBeingProcessed ? (
												<div className={'flex'}>
													{' '}
													<Button
														onClick={uploadHandler}
														color="success"
														variant="contained"
														size="medium"
														disabled={
															!formik.isValid ||
															(formik.values.created === '' &&
																cloudProcessing.configManuallyUploaded)
														}
														className="mx-4 h-10 cursor-pointer normal-case">
														{t('PROCESS')}
													</Button>
												</div>
											) : (
												<Button
													onClick={uploadHandler}
													color="success"
													variant="contained"
													size="medium"
													disabled={
														!formik.isValid ||
														(formik.values.created === '' && cloudProcessing.configManuallyUploaded)
													}
													className="mx-4 h-10 cursor-pointer normal-case">
													{t('PROCESS')}
												</Button>
											)}
										</div>
									</>
								)
							)}
						</div>
						{video || reprocessingMeasurement.reprocessing ? (
							<div className="flex flex-col items-center justify-center pt-2">
								{uploadingVideo || videoBeingProcessed ? (
									<>
										{uploadingVideo && (
											<div className="mt-2 flex h-14 flex-col items-center justify-around">
												<CircularProgress size={25} />
												<div className="font-normal text-gray-600">{t('UPLOADING') + ' ...'}</div>
											</div>
										)}
										{videoBeingProcessed && <Processing />}
									</>
								) : (
									<>
										<div
											className={classNames({
												'min-h-14 mt-2 flex items-center': true,
												'flex-col': videoUploaded
											})}>
											{!reprocessingMeasurement.reprocessing && !videoUploaded ? (
												<>
													{!cloudProcessing.configManuallyUploaded && (
														<Button
															onClick={uploadHandler}
															color="success"
															variant="contained"
															size="medium"
															disabled={!formik.isValid}
															className="mx-4 h-10 cursor-pointer normal-case">
															{t('UPLOAD')}
														</Button>
													)}
												</>
											) : (
												!cloudProcessing.configManuallyUploaded && (
													<div className={'pb-2'}>
														<ImageRotation />
													</div>
												)
											)}
										</div>
									</>
								)}
							</div>
						) : (
							<div className="flex flex-col items-center justify-center"></div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default UploadVideo;
