import { useGlobalStore } from 'global-state/useStore';
import CloudProcessing from 'pages/CloudProcessing/CloudProcessing';
import CloudProcessingHome from 'pages/CloudProcessing/CloudProcessingHome';
import MeasurementsPage from 'pages/MeasurementsPage/MeasurementsPage';
import SiteDetailsPage from 'pages/SiteDetails/SiteDetails';
import React, { useState, useEffect } from 'react';
import { useParams, Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { OrganizationsService } from 'services/OrganizationsService/OrganizationService';
import { useAuth } from 'rq/hooks/authHook';
import { ErrorBoundary } from 'react-error-boundary';

import SomethingWentWrong from '../components/Error/SomethingWentWrong';
import MapPage from '../pages/Map/MapPage';
import OrganizationPage from '../pages/Organization/OrganizationPage';
import ProfilePage from '../pages/ProfilePage/ProfilePage';
import SitesPage from '../pages/Sites/SitesPage';
import UsersPage from '../pages/Users/UsersPage';
import Alarms from '../pages/Alarms/Alarms';
import { OrganizationListRetrieve } from 'interfaces/models/OrganizationListRetrieve';

const OrganizationRouter = () => {
	const [loading, setLoading] = useState(true);
	const location = useLocation();
	const locationState = location.state as
		| {
				orgName: string;
		  }
		| undefined;

	const params = useParams();
	const navigate = useNavigate();

	const { error } = useGlobalStore((state) => state.joinOrRedirectOrganizationModal);

	const toggleJoinOrRedirectOrganizationModal = useGlobalStore(
		(state) => state.toggleJoinOrRedirectOrganizationModal
	);
	const { data: user } = useAuth();

	useEffect(() => {
		setLoading(true);
		const localstorageOrgId = Number(localStorage.getItem('currentOrganizationId'));
		const paramId = Number(params.orgId);
		if (isNaN(paramId)) {
			navigate(`/${localstorageOrgId}`);
		} else {
			if (
				user?.super_admin === true ||
				(user?.organizations?.some((org: OrganizationListRetrieve) => org.id === paramId) &&
					paramId)
			) {
				OrganizationsService.switchOrganization(paramId);
			} else {
				toggleJoinOrRedirectOrganizationModal(true, paramId, `${locationState?.orgName}`, true);
			}
		}
		setLoading(false);
	}, [params.orgId]);

	if (loading) {
		return <div></div>;
	}

	return (
		<>
			{error ? (
				<></>
			) : (
				<>
					<Routes>
						<Route
							path={'sites'}
							element={
								<ErrorBoundary
									key={1}
									FallbackComponent={(err) => {
										return (
											<SomethingWentWrong
												error={{ error: err.error, resetErrorBoundary: err.resetErrorBoundary }}
												tryAgain={err.resetErrorBoundary}
											/>
										);
									}}>
									<SitesPage isCloudProcessing={false} />
								</ErrorBoundary>
							}
						/>
						<Route
							path={'sites/:siteId'}
							element={
								<ErrorBoundary
									key={2}
									FallbackComponent={(err) => {
										return (
											<SomethingWentWrong
												error={{ error: err.error, resetErrorBoundary: err.resetErrorBoundary }}
												tryAgain={err.resetErrorBoundary}
											/>
										);
									}}>
									<SiteDetailsPage />
								</ErrorBoundary>
							}
						/>{' '}
						<Route
							path={'measurements'}
							element={
								<ErrorBoundary
									key={3}
									FallbackComponent={(err) => {
										return (
											<SomethingWentWrong
												error={{ error: err.error, resetErrorBoundary: err.resetErrorBoundary }}
												tryAgain={err.resetErrorBoundary}
											/>
										);
									}}>
									<MeasurementsPage />
								</ErrorBoundary>
							}
						/>
						<Route
							path={'measurements/:siteId/:stationId'}
							element={
								<ErrorBoundary
									key={4}
									FallbackComponent={(err) => {
										return (
											<SomethingWentWrong
												error={{ error: err.error, resetErrorBoundary: err.resetErrorBoundary }}
												tryAgain={err.resetErrorBoundary}
											/>
										);
									}}>
									<MeasurementsPage />
								</ErrorBoundary>
							}
						/>
						<Route
							path={'users'}
							element={
								<ErrorBoundary
									key={5}
									FallbackComponent={(err) => {
										return (
											<SomethingWentWrong
												error={{ error: err.error, resetErrorBoundary: err.resetErrorBoundary }}
												tryAgain={err.resetErrorBoundary}
											/>
										);
									}}>
									<UsersPage />
								</ErrorBoundary>
							}
						/>
						<Route
							path={'users/:userId'}
							element={
								<ErrorBoundary
									key={6}
									FallbackComponent={(err) => {
										return (
											<SomethingWentWrong
												error={{ error: err.error, resetErrorBoundary: err.resetErrorBoundary }}
												tryAgain={err.resetErrorBoundary}
											/>
										);
									}}>
									<ProfilePage />
								</ErrorBoundary>
							}
						/>
						<Route path={'map'} element={<MapPage />} />
						<Route path={'organization'} element={<OrganizationPage />} />
						<Route path={'alarms'} element={<Alarms />} />
						<Route path={'cloudprocessing'} element={<CloudProcessingHome />} />
						<Route path={'cloudprocessing/:siteId'} element={<CloudProcessing />} />
						<Route
							path={'cloudprocessing/:siteId/:stationId/:measurementId'}
							element={<CloudProcessing />}
						/>
						<Route path={'*'} element={<Navigate to={`map`} />} />
					</Routes>
				</>
			)}
		</>
	);
};

export default OrganizationRouter;
