import { Upload, Warning } from '@mui/icons-material';
import { Button, TextField } from '@mui/material';
import IconWithTooltip from 'components/shared/Tooltip/IconWithTooltip';
import { useFormik } from 'formik';
import { useGlobalStore } from 'global-state/useStore';
import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { queryKeys } from 'rq/constants';
import { useUpdateStation } from 'rq/hooks/stationHook';
import { queryClient } from 'rq/queryClient';
import { handleFileReadAsString, validateMarkerCoordinates } from 'utils/functions';
import * as Yup from 'yup';

const GCPCoordinates = () => {
	const { t } = useTranslation();
	const uploadInputRef = useRef<HTMLInputElement | null>(null);
	const [fileContent, setFileContent] = useState<string | null>(null);

	const { stationDetails } = useGlobalStore((state) => state.editGCPModal);
	const selectedSiteId = useGlobalStore((state) => state.selectedSiteId);
	const { mutate: updateStation } = useUpdateStation();

	const [coordinatesValidationError, setCoordinatesValidationError] = useState({
		errorMessage: '',
		isValid: false
	});

	const formik = useFormik({
		initialValues: {
			markers_coordinates: stationDetails?.markers_coordinates?.join(',') ?? ''
		},
		validationSchema: Yup.object({
			markers_coordinates: Yup.string().test(
				'validate-coordinates',
				'Invalid coordinates',
				(value: string | undefined) => validateMarkerCoordinates(value).isValid
			)
		}),
		onSubmit: (values) => {
			const station = {
				...stationDetails,
				markers_coordinates: values.markers_coordinates.split(',').map(Number)
			};

			stationDetails?.station_id &&
				updateStation(
					{
						siteId: Number(selectedSiteId),
						stationId: stationDetails?.station_id,
						data: station
					},
					{
						onSuccess: () => {
							toast.success(t('STATION_UPDATED'));
							queryClient.invalidateQueries([
								queryKeys.station_details,
								`site-id-${selectedSiteId}`,
								`station-id-${stationDetails.station_id}`
							]);
						}
					}
				);
		}
	});

	useEffect(() => {
		setCoordinatesValidationError(validateMarkerCoordinates(formik.values.markers_coordinates));
	}, [formik.values.markers_coordinates]);

	const handleUploadClick = () => {
		uploadInputRef.current?.click();
	};
	const changeCoordsOnUpload = (coords: string) => {
		setFileContent(coords);
	};

	useEffect(() => {
		fileContent && formik.setFieldValue('markers_coordinates', fileContent);
	}, [fileContent]);

	return (
		<form onSubmit={formik.handleSubmit}>
			<div className={'flex flex-col gap-4'}>
				<div className={'flex w-full flex-row items-center  gap-8 align-middle'}>
					<div className={'flex w-full flex-col gap-2'}>
						<div>{t('MARKER_COORDINATES')}</div>
						<TextField
							multiline={true}
							rows={5}
							variant={'standard'}
							className={'w-full'}
							name="markers_coordinates"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.markers_coordinates}
						/>

						<div className={'h-max pb-2 text-red-500'}>
							{coordinatesValidationError.errorMessage}
						</div>
					</div>
					<input
						type="file"
						className={'hidden'}
						ref={uploadInputRef}
						onChange={(e) => handleFileReadAsString(e, changeCoordsOnUpload)}
						accept="*"
					/>
					<IconWithTooltip icon={<Upload onClick={handleUploadClick} />} title={t('UPLOAD_FILE')} />
				</div>
				<div className={'flex flex-col gap-2 text-sm'}>
					<div className={'flex items-center gap-2 align-middle text-base'}>
						<Warning color={'error'} /> <span className={'font-bold'}>{t('IMPORTANT')}</span>
					</div>
					<div>{t('COORDINATES_WARNING')}</div>
					<div>{t('COORDINATES_INPUT_WARNING')}</div>
				</div>
				<div className={'flex flex-row justify-center pb-4'}>
					<Button
						variant={'contained'}
						type={'submit'}
						disabled={!formik.isValid || !formik.dirty}
						className={'normal-case'}>
						{t('UPDATE')}
					</Button>
				</div>
			</div>
		</form>
	);
};
export default GCPCoordinates;
