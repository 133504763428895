import { BarChart, CloudSync } from '@mui/icons-material';
import classNames from 'classnames';
import { useGlobalStore } from 'global-state/useStore';
import { useEffect, useState } from 'react';
import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LocationIcon from '@mui/icons-material/LocationOn';
import GroupIcon from '@mui/icons-material/Group';
import DomainIcon from '@mui/icons-material/Domain';
import MapIcon from '@mui/icons-material/Map';
import AlarmIcon from '@mui/icons-material/Alarm';
import { NavLink, useLocation } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { useAuth } from 'rq/hooks/authHook';
import PermissionView from '../shared/PermissionsView/PermissionView';

export const MainListItems = () => {
	const location = useLocation();
	const { t } = useTranslation();
	const [selectedItem, setSelectedItem] = useState(location.pathname.split('/')[2]);
	const orgId = useGlobalStore((state) => state.currentOrganization.id);
	const [orgIdState, setOrgIdState] = useState(orgId);
	const { data: user } = useAuth();

	useEffect(() => {
		//on every route change set selected menu item, it is for now third item when we split location pathname by /
		setSelectedItem(location.pathname.split('/')[2]);
	}, [location]);

	useEffect(() => {
		setOrgIdState(orgId);
	}, [orgId]);

	return (
		<div className={'overflow-hidden'}>
			<NavLink
				to={`/${orgIdState}/map`}
				className={({ isActive }) =>
					isActive ? 'block border-0 bg-primary text-black' : 'text-primary'
				}>
				<ListItemButton onClick={() => setSelectedItem('map')}>
					<ListItemIcon style={{ minWidth: '2.5rem' }}>
						<MapIcon
							className={classNames(
								{ 'text-primary': selectedItem !== 'map' },
								{ 'text-accent': selectedItem === 'map' }
							)}
						/>
					</ListItemIcon>
					<ListItemText primary={t('MAP')} />
				</ListItemButton>
			</NavLink>
			<NavLink
				to={`/${orgIdState}/sites`}
				className={({ isActive }) => (isActive ? 'block bg-primary text-black' : 'text-primary')}>
				<ListItemButton onClick={() => setSelectedItem('sites')}>
					<ListItemIcon style={{ minWidth: '2.5rem' }}>
						<LocationIcon
							className={classNames(
								{ 'text-primary': selectedItem !== 'sites' },
								{ 'text-accent': selectedItem === 'sites' }
							)}
						/>
					</ListItemIcon>
					<ListItemText primary={t('SITES')} className={'no-underline'} />
				</ListItemButton>
			</NavLink>
			<NavLink
				to={`/${orgIdState}/measurements`}
				className={({ isActive }) => (isActive ? 'block bg-primary text-black' : 'text-primary')}>
				<ListItemButton onClick={() => setSelectedItem('measurements')}>
					<ListItemIcon style={{ minWidth: '2.5rem' }}>
						<BarChart
							className={classNames(
								{ 'text-primary': selectedItem !== 'measurements' },
								{ 'text-accent': selectedItem === 'measurements' }
							)}
						/>
					</ListItemIcon>
					<ListItemText primary={t('MEASUREMENTS')} className={'no-underline'} />
				</ListItemButton>
			</NavLink>
			<NavLink
				to={`/${orgIdState}/organization`}
				className={({ isActive }) => (isActive ? 'block bg-primary text-black' : 'text-primary')}>
				<ListItemButton onClick={() => setSelectedItem('organization')}>
					<ListItemIcon style={{ minWidth: '2.5rem' }}>
						<DomainIcon
							className={classNames(
								{ 'text-primary': selectedItem !== 'organization' },
								{ 'text-accent': selectedItem === 'organization' }
							)}
						/>
					</ListItemIcon>
					<ListItemText primary={t('ORGANIZATION')} />
				</ListItemButton>
			</NavLink>
			<PermissionView requiredPermissions={'manage_sites'} showFallbackComponent={false}>
				<NavLink
					to={`/${orgIdState}/alarms`}
					className={({ isActive }) => (isActive ? 'block bg-primary text-black' : 'text-primary')}>
					<ListItemButton onClick={() => setSelectedItem('alarms')}>
						<ListItemIcon style={{ minWidth: '2.5rem' }}>
							<AlarmIcon
								className={classNames(
									{ 'text-primary': selectedItem !== 'alarms' },
									{ 'text-accent': selectedItem === 'alarms' }
								)}
							/>
						</ListItemIcon>
						<ListItemText primary={t('Alarms')} />
					</ListItemButton>
				</NavLink>
			</PermissionView>
			<PermissionView requiredPermissions={'view_users'} showFallbackComponent={false}>
				<NavLink
					to={`/${orgIdState}/users`}
					className={({ isActive }) => (isActive ? 'block bg-primary text-black' : 'text-primary')}>
					<ListItemButton onClick={() => setSelectedItem('users')}>
						<ListItemIcon style={{ minWidth: '2.5rem' }}>
							<GroupIcon
								className={classNames(
									{ 'text-primary': selectedItem !== 'users' },
									{ 'text-accent': selectedItem === 'users' }
								)}
							/>
						</ListItemIcon>
						<ListItemText primary={t('USERS')} />
					</ListItemButton>
				</NavLink>
			</PermissionView>{' '}
			{user && user.super_admin && (
				<PermissionView requiredPermissions={'create_measurements'} showFallbackComponent={false}>
					<NavLink
						to={`/${orgIdState}/cloudprocessing`}
						className={({ isActive }) =>
							isActive ? 'block bg-primary text-black' : 'text-primary'
						}>
						<ListItemButton onClick={() => setSelectedItem('cloudprocessing')}>
							<ListItemIcon style={{ minWidth: '2.5rem ' }}>
								<CloudSync
									className={classNames(
										{ 'text-primary': selectedItem !== 'cloudprocessing' },
										{ 'text-accent': selectedItem === 'cloudprocessing' }
									)}
								/>
							</ListItemIcon>
							<ListItemText primary={t('CLOUD_PROCESSING')} />
						</ListItemButton>
					</NavLink>
				</PermissionView>
			)}
		</div>
	);
};
