import { useGlobalStore } from 'global-state/useStore';
import React from 'react';
import List from '@mui/material/List';
import { MainListItems } from './MenuItems';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';

const drawerWidth = 200;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
	({ theme, open }) => ({
		'& .MuiDrawer-paper': {
			[theme.breakpoints.down(768)]: {
				width: open ? '100vw' : 0
			},
			position: 'relative',
			zIndex: 10,
			whiteSpace: 'nowrap',
			color: 'white',
			width: drawerWidth,
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen
			}),
			boxSizing: 'border-box',
			...(!open && {
				overflowX: 'hidden',
				transition: theme.transitions.create('width', {
					easing: theme.transitions.easing.sharp,
					duration: theme.transitions.duration.leavingScreen
				}),
				width: '3.5rem'
			})
		}
	})
);

const NavBar = ({ open }: { open: boolean }) => {
	const { isSeba } = useGlobalStore((state) => state.hostname);

	return (
		<>
			<Drawer variant="permanent" className={'static z-50 h-full max-md:absolute'} open={open}>
				<List component="nav" className={'flex h-full flex-col justify-between bg-secondary'}>
					<MainListItems />
					{isSeba && open && <img src={'/assets/seba-vector-logo.svg'} alt={'Seba logo'}></img>}
					{isSeba && !open && (
						<img
							src={'/assets/seba-vector-icon.svg'}
							className={'ml-1 max-h-40'}
							alt={'Seba logo'}></img>
					)}
					{!isSeba && open && (
						<img src={'/assets/Discharge_logotip_menu.svg'} alt={'Discharge logo'}></img>
					)}
					{!isSeba && !open && (
						<img
							src={'/assets/Discharge_icon.png'}
							alt={'Discharge logo'}
							className={'h-14 w-14'}></img>
					)}
				</List>
			</Drawer>
		</>
	);
};

export default NavBar;
