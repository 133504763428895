import DomainIcon from '@mui/icons-material/Domain';
import Avatar from '@mui/material/Avatar';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import PrimaryButton from 'components/shared/Button/PrimaryButton';
import Modal from 'components/shared/Modal/Modal';
import PermissionView from 'components/shared/PermissionsView/PermissionView';
import { useGlobalStore } from 'global-state/useStore';
import { OrganizationListRetrieve } from 'interfaces/models/OrganizationListRetrieve';
import React, { useMemo, useState } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { queryKeys } from 'rq/constants';
import { useAuth } from 'rq/hooks/authHook';
import { usePublicOrganizations } from 'rq/hooks/organizationsHook';
import { queryClient } from 'rq/queryClient';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { IModalProps } from './types';
import CreateOrganizationModal from '../CreateOrganization/CreateOrganizationModal';
import { OrganizationCreateUpdateDestroy } from 'interfaces/models/OrganizationCreateUpdateDestroy';

const OrganizationSwitcher = (props: IModalProps) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { data: user } = useAuth();
	const { data: publicOrganizations, isLoading: publicOrgLoading } = usePublicOrganizations();
	const userOrganizations = user?.organizations ?? [];
	const organizationSwitcher = useGlobalStore((state) => state.organizationSwitcher);

	const [createOrganizationModalOpen, setCreateOrganizationModalOpen] = useState<boolean>(false);
	const [searchTerm, setSearchTerm] = useState('');

	const chooseAnOrganization = (org: OrganizationListRetrieve) => {
		props.handleModalClose();
		navigate(`/${org.id.toString()}`, { state: { orgName: org.name } });
	};

	const createNewOrganization = () => {
		setCreateOrganizationModalOpen(true);
	};

	const closeCreateNewOrganizationModal = () => {
		setCreateOrganizationModalOpen(false);
	};

	const orgCreated = (org: OrganizationCreateUpdateDestroy) => {
		queryClient.invalidateQueries(queryKeys.me);
		navigate(`/${org.id}`);
		props.handleModalClose();
	};
	const userOrganizationsState = useMemo(() => {
		const organizations = userOrganizations.filter((org) =>
			org.name.toLowerCase().includes(searchTerm.toLowerCase())
		);
		return organizations?.map((org: OrganizationListRetrieve) => {
			return (
				<div
					className="flex cursor-pointer flex-row items-center gap-2 p-2 hover:bg-gray-200"
					data-org-id={org.id}
					onClick={chooseAnOrganization.bind(this, org)}
					key={org.id}>
					<Avatar className="mx-0 mr-2 h-10 w-10 bg-blue-300" src={org.logo}>
						<DomainIcon />
					</Avatar>
					<div className="flex flex-col justify-center">
						<div>{org.name}</div>
					</div>
				</div>
			);
		});
	}, [userOrganizations, searchTerm]);

	const publicOrganizationsState = useMemo(() => {
		const publicOrgs = publicOrganizations?.filter(
			(publicOrg) =>
				!userOrganizations.some((userOrg) => publicOrg.id === userOrg.id) &&
				publicOrg.name.toLowerCase().includes(searchTerm.toLowerCase())
		);

		return publicOrgs?.map((pubOrg: OrganizationListRetrieve) => {
			return (
				<div
					className="flex cursor-pointer flex-row items-center gap-2 p-2 hover:bg-gray-200"
					data-org-id={pubOrg.id}
					onClick={chooseAnOrganization.bind(this, pubOrg)}
					key={pubOrg.id}>
					<Avatar className="h-10 w-10 bg-blue-300" src={pubOrg.logo}>
						<DomainIcon />
					</Avatar>
					<div className="flex flex-col justify-center">
						<div>{pubOrg.name}</div>
					</div>
				</div>
			);
		});
	}, [publicOrganizations, searchTerm]);

	return (
		<div>
			<Modal
				open={props.open}
				closeModal={props.handleModalClose}
				modalTitle={t('SWITCH_ORGANIZATION')}>
				<div className={'h-20'}>
					<PermissionView requiredPermissions={'manage_organization'} showFallbackComponent={false}>
						<div className="mb-2 flex w-full flex-row justify-end p-2 md:mb-6">
							<PrimaryButton
								onClick={createNewOrganization}
								className={'flex gap-2 bg-secondary normal-case'}>
								<AddCircleOutlineIcon className={'text-white'} />
								<span className={'text-white'}>{t('CREATE_ORGANIZATION')}</span>
							</PrimaryButton>
						</div>
					</PermissionView>
				</div>
				<div className={'flex w-full justify-center '}>
					<TextField
						value={searchTerm}
						label={t('SEARCH_ORGS')}
						onChange={(e) => {
							setSearchTerm(e.target.value);
						}}></TextField>
				</div>

				<div className="h-[90vh] min-w-[50rem] flex-1 overflow-y-auto p-4">
					{organizationSwitcher.organizations && (
						<div className={'flex justify-center'}>{t('SWITCH_ORG_TO_CONTINUE')}</div>
					)}
					<div className={'flex flex-col gap-4'}>
						<div className={'self-center font-bold'}>{t('USER_ORGANIZATIONS')}</div>
						<div className={'flex flex-col'}>
							{userOrganizationsState.length > 0 ? (
								userOrganizationsState
							) : (
								<div className={'self-center'}>{t('NO_RESULTS')}</div>
							)}
						</div>
						<div className={'self-center font-bold'}>{t('OTHER_ORGANIZATIONS')}</div>
						{publicOrgLoading ? (
							<div className={'flex w-full flex-col items-center justify-center p-10'}>
								<CircularProgress />
								<span>{t('LOADING')}</span>
							</div>
						) : (
							<div className={'flex flex-col '}>
								{publicOrganizationsState && publicOrganizationsState.length > 0 ? (
									publicOrganizationsState
								) : (
									<div className={'self-center'}>{t('NO_RESULTS')}</div>
								)}
							</div>
						)}
					</div>
				</div>
				{createOrganizationModalOpen && (
					<CreateOrganizationModal
						open={createOrganizationModalOpen}
						closeModal={closeCreateNewOrganizationModal}
						afterCreatingOrganization={orgCreated}
					/>
				)}
			</Modal>
		</div>
	);
};

export default OrganizationSwitcher;
