import classNames from 'classnames';
import AppBar from 'components/AppBar';
import NavBar from 'components/NavBar';
import React, { FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';
import { ILayout } from './types';

const Layout: FunctionComponent<ILayout> = ({ children }) => {
	const [open, setOpen] = React.useState(false);
	const toggleDrawer = () => {
		setOpen(!open);
	};

	return (
		<div className={'app flex h-screen flex-1 flex-col overflow-hidden bg-primary'}>
			<AppBar open={open} drawerToggle={toggleDrawer} className={'bg-secondary'} />
			<div className="flex w-full flex-1 overflow-auto">
				<>
					<NavBar open={open} />

					<div
						className={classNames(
							{ 'h-full w-full flex-1 overflow-auto': true },
							{ 'p-4 max-sm:p-3': !useLocation().pathname.includes('map') }
						)}>
						{children}
					</div>
				</>
			</div>
		</div>
	);
};
export default Layout;
