import { ICardProps } from 'components/shared/Cards/types';
import React, { FunctionComponent } from 'react';

const Card: FunctionComponent<ICardProps> = ({ children, cardTitle, actionIcon }) => {
	return (
		<div className={'flex h-full flex-col rounded-xl border border-black'}>
			{cardTitle && <div>{cardTitle}</div>}
			<div className={'flex-1 rounded-xl'}>{children}</div>
			<div className={'flex justify-end px-2 py-1'}>{actionIcon}</div>
		</div>
	);
};
export default Card;
