import { useGlobalStore } from 'global-state/useStore';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { IMapTooltipProps } from './types';

const MapTooltip: FunctionComponent<IMapTooltipProps> = ({ name, latitude, longitude, siteId }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	const currentOrganization = useGlobalStore((state) => state.currentOrganization);
	return (
		<>
			<p className={' text-2xl font-bold'}>{name}</p>
			<p>
				{t('LATITUDE')}: {latitude}
			</p>
			<p>
				{t('LONGITUDE')}: {longitude}
			</p>
			<a
				className={'cursor-pointer text-lg underline'}
				onClick={() => navigate(`/${currentOrganization.id}/sites/${siteId}`)}>
				{t('VIEW')} {t('SITE').toLowerCase()}
			</a>
		</>
	);
};
export default MapTooltip;
