import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { IRatingCurveProps } from 'components/Measurements/MeasurementCharts/types';
import RatingCurveDataGrid from 'components/Measurements/RatingCurve/RatingCurveDataGrid';
import { useGlobalStore } from 'global-state/useStore';
import { StationMeasurementsModel } from 'interfaces/models/StationMeasurementsModel';
import React, { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	ScatterController
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
import { useTranslation } from 'react-i18next';
import { useRatingCurveMeasurements } from 'rq/hooks/measurementsHook';
import { useRatingCurves } from 'rq/hooks/stationHook';
import { calculateCurveData, calculateMinMaxData, createMeasurementData } from 'utils/ratingCurve';
import zoomPlugin from 'chartjs-plugin-zoom';

// Register the components
ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	ScatterController,
	zoomPlugin
);

const RatingCurve: FunctionComponent<IRatingCurveProps> = ({ siteId, stationId }) => {
	const { t } = useTranslation();

	const { data: ratingCurves } = useRatingCurves(siteId, stationId, {
		enabled: !!stationId && !!siteId
	});

	const ratingCurveData = useGlobalStore((state) => state.ratingCurveData);
	const setRatingCurveData = useGlobalStore((state) => state.setRatingCurveData);
	const ratingCurveFormData = useGlobalStore((state) => state.ratingCurveFormData);
	const setRatingCurveFormData = useGlobalStore((state) => state.setRatingCurveFormData);

	useEffect(() => {
		if (!ratingCurveFormData.formToggled) {
			const curves = ratingCurves?.map((curve) => {
				return {
					...curve,
					isVisible:
						curve.active ||
						ratingCurveData?.ratingCurves?.some((item) => item.id === curve.id && item.isVisible)
				};
			});
			setRatingCurveData({
				station_id: Number(stationId),
				site_id: Number(siteId),
				ratingCurves: curves
			});
		}
	}, [ratingCurves, ratingCurveFormData.formToggled]);

	const [siteIdState, setSiteIdState] = useState(siteId);
	const [stationIdState, setStationIdState] = useState(stationId);

	const [selectedDataSetIndex, setSelectedDataSetIndex] = useState<number | null>();

	useEffect(() => {
		setSiteIdState(siteId);
		setStationIdState(stationId);
		setRatingCurveFormData({
			rating_curve: null,
			formToggled: false
		});
	}, [stationId, siteId]);

	const { data: measurementsData, isLoading: chartDataLoading } = useRatingCurveMeasurements(
		{
			station_id: stationIdState,
			site_id: siteIdState,

			queryParams: {
				group: false,
				ordering: 'date_time',
				return_all: true
			}
		},
		{ enabled: !!siteIdState && !!stationIdState }
	);

	const minMaxData = useMemo(() => {
		return calculateMinMaxData(
			measurementsData ? (measurementsData as StationMeasurementsModel[]) : []
		);
	}, [measurementsData]);

	const chartRef = useRef<ChartJSOrUndefined<'line', { x: number; y: number }[] | undefined>>();

	const resetZoom = () => {
		if (chartRef.current) {
			const chartInstance = chartRef.current;
			if (chartInstance) {
				chartInstance.resetZoom();
			}
		}
	};

	const measurementData = useMemo(() => {
		return (
			measurementsData && createMeasurementData(measurementsData as StationMeasurementsModel[])
		);
	}, [measurementsData]);

	const colors = ['#9EA4CA', '#9BCAC1', '#CAC98B', '#CA8883', '#CA94C6'];

	const ratingCurveSeries = useMemo(() => {
		const ratingCurvesData = ratingCurveData.ratingCurves ? [...ratingCurveData.ratingCurves] : [];
		return (
			ratingCurvesData
				.filter((value: any) => value.isVisible)
				.map((curve, index) => {
					return {
						type: 'line' as const,
						label: curve.model,
						data:
							curve &&
							calculateCurveData(
								curve,
								measurementsData as StationMeasurementsModel[],
								minMaxData
							)?.map((item) => {
								return {
									x: item[0],
									y: item[1]
								};
							}),
						borderColor: curve.active ? '#5C6BC0' : colors[index],
						borderWidth: 2,
						hoverRadius: 6,
						hoverBorderWidth: 3,
						hoverBorderColor: curve.active ? '#5C6BC0' : colors[index],
						order: 0,
						fill: false,
						showLine: true,
						pointRadius: 0,
						tension: 0.1
					};
				}) ?? []
		);
	}, [measurementsData, ratingCurveData.ratingCurves, ratingCurves]);

	const data = useMemo(() => {
		return {
			datasets: [
				{
					type: 'scatter' as const,
					label: t('MEASUREMENTS'),
					order: 1,
					data: measurementData?.dischargeMeasurementsApproved,
					backgroundColor:
						selectedDataSetIndex && selectedDataSetIndex !== 0
							? 'rgba(38,166,154, 0.1)'
							: 'rgba(38,166,154)'
				},
				{
					type: 'scatter' as const,
					label: t('MEASUREMENTS_PENDING'),
					order: 2,
					data: measurementData?.dischargeMeasurementsPending,
					backgroundColor:
						selectedDataSetIndex && selectedDataSetIndex !== 1
							? 'rgba(117,117,117, 0.1)'
							: 'rgba(117,117,117, 0.1)'
				},
				...ratingCurveSeries
			]
		};
	}, [measurementData, ratingCurveSeries, selectedDataSetIndex]);

	const options = useMemo(
		() => ({
			animation: false as const,
			interaction: {
				mode: 'nearest' as const,
				intersect: false
			},
			scales: {
				x: {
					type: 'linear' as const,
					position: 'bottom' as const,
					title: {
						display: true,
						text: `${t('DISCHARGE')} (m3/s)`
					},
					min:
						minMaxData.lowestDischarge -
						(minMaxData.lowestDischarge * 1.1 - minMaxData.lowestDischarge),
					max: minMaxData.highestDischarge * 1.1
				},
				y: {
					title: {
						display: true,
						text: `${t('LEVEL')} (m)`
					}
				}
			},
			plugins: {
				legend: {
					display: true,
					position: 'bottom' as const
				},
				tooltip: {
					callbacks: {
						label: function (context: any) {
							setSelectedDataSetIndex(context.datasetIndex);
							const label = context.dataset.label || '';
							const level = context.parsed.y.toFixed(3);
							const discharge = context.parsed.x.toFixed(3);
							return [label, `Level: ${level} m`, `Discharge: ${discharge} m³/s`];
						}
					}
				},
				zoom: {
					zoom: {
						pinch: {
							enabled: true,
							mode: 'x' as const
						},
						drag: {
							enabled: true,
							mode: 'x' as const,
							borderColor: 'rgb(54, 162, 235)',
							borderWidth: 1,
							backgroundColor: 'rgba(54, 162, 235, 0.3)'
						}
					}
				}
			},
			responsive: true,
			maintainAspectRatio: false
		}),
		[minMaxData]
	);

	return (
		<div className={'w-full'}>
			<div className={' min-h-96 h-96 w-full'}>
				{chartDataLoading ? (
					<div className={'flex h-full flex-col items-center justify-center gap-2 align-middle'}>
						<CircularProgress />
						<div>{t('LOADING')}</div>
					</div>
				) : measurementsData?.length === 0 || !measurementsData ? (
					<div className={'flex h-full flex-col items-center justify-center align-middle'}>
						<div>{t('NO_MEASUREMENTS_FOR_FILTERS')}</div>
					</div>
				) : (
					<div className={'flex flex-col gap-y-12 p-2 xl:flex-row xl:gap-4'}>
						<div className={'flex h-[30rem] flex-col xl:w-1/2'}>
							<div className={' flex w-full items-center justify-end gap-4 px-8 align-middle'}>
								<Button onClick={resetZoom} variant={'outlined'}>
									{t('RESET_ZOOM')}
								</Button>
							</div>

							<Chart ref={chartRef} type={'scatter'} data={data} options={options} />
						</div>
						<div className={' mt-12 xl:w-1/2'}>
							<RatingCurveDataGrid></RatingCurveDataGrid>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default RatingCurve;
